import { Button, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import React, { memo, useEffect, useState } from 'react'
import Link from '@mui/material/Link';
import { useTheme } from "@mui/material/styles";
import { getClientPortalURL } from 'commonApi';
import Loader from 'ui-component/Loader';

const SubscriptionPlans = memo((props) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] =useState(false)
  const [redirectURL, setRedirectURL] = useState()

  const getStripeURL = async () =>{
    setIsLoading(true)
    await getClientPortalURL(props.account_id)
      .then(res => {
        setIsLoading(false)
        setRedirectURL(res.url)
        // window.open(res.url, '_blank', 'noopener, noreferrer');
        // const link = document.createElement('a');
        // link.href = res.url;
        // link.target = '_blank';
        // link.click();
    }).catch(err => {
      setIsLoading(false)
      console.log(err)
    })
  }

  useEffect(()=>{
    if((props?.payment_status ?? '').toLowerCase() === 'paid') getStripeURL()
  },[])

  return (
    <Grid container spacing={2} >
      {isLoading && <Loader />}
      <Grid item xs={12} md={12} >
        {(props?.payment_status ?? '').toLowerCase() !== 'paid' ? 
          <stripe-pricing-table 
            pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
            publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
            client-reference-id={props.billing_customer_secret ? '':props.billing_contact_id}
            customer-email={props.billing_customer_secret ? '': props.email}
            customer-session-client-secret={props.billing_customer_secret}
          />
        : <Paper>
            <Typography variant="h1" sx={{ ...theme.typography.mainContainerHeader  }}>Manage Your Subscription</Typography>
            <Typography variant="h5" sx={{ ...theme.typography.h5, padding: '5px 30px'  }}>
              Your Subscription is ACTIVE ( {props.subPlanName} ), Upgrade / Downgrade/ Cancel your existing subscription
            </Typography>
            <div style={{width: '100%', height: 300, display: 'grid', placeItems: 'center'}}>
              <a
                href={redirectURL}
                target='_blank'
                rel='noreferrer noopener'
                style={{
                  padding: '10px 20px', background: isLoading ? 'gray' : '#1976D2', color: '#fff',
                  borderRadius: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10
                }}
                // variant='contained' 
                // size='large'
                // endIcon={isLoading && <CircularProgress color="inherit" size={18}/>}
                // onClick={() => redirectToStripe(props.account_id)}
              >
                Manage Subscription {isLoading && <CircularProgress color="inherit" size={18}/>}
              </a>
            </div>
          </Paper>
        }
      </Grid>
    </Grid>
  )
})

export default SubscriptionPlans