// material-ui
import { Typography } from "@mui/material";
import { useCookies } from "react-cookie";

// project imports
import NavGroup from "./NavGroup";
import menuItem from "menu-items";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const [cookies, setCookie] = useCookies(["login_session"]);
  const superAdmin = cookies?.login_session?.is_super_admin;
  const supportAdmin = cookies?.login_session?.is_support_admin;

  const navItems = menuItem.items
    .filter((e) => {
      if (!superAdmin && !supportAdmin) {
        return e.id !== "super_admin" && e.id !== "support_admin";
      } else if (!superAdmin) {
        return e.id !== "super_admin";
      } else if (!supportAdmin) {
        return e.id !== "support_admin";
      } else if (superAdmin && supportAdmin) {
        return true
      } else {
        return true;
      }
    })
    .map((item,i) => {
      switch (item.type) {
        case "group":
          return <NavGroup key={`${item.id}_${i}`} item={item} />;
        default:
          return (
            <Typography key={`${item.id}_${i}`} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });

  return <>{ navItems }</>;
};

export default MenuList;
