import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import {
  Skeleton,
  Stack,
  PaginationItem,
  Pagination,
  Tooltip,
  IconButton,
  Checkbox,
  Paper,
  Typography,
  Toolbar,
  TableSortLabel,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Box,
  Link,
  Chip,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import { IconChevronsLeft, IconChevronsRight } from "@tabler/icons";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import RowsPerPageSelector from "./RowsPerPageSelector";
import config from "config";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import { isMobile } from "react-device-detect";
import { useEffect } from "react";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import TableActionSection from "./TableActionSection";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

function descendingComparator(a, b, orderBy) {
  const valueA = typeof a[orderBy] === 'string' && !isNaN(a[orderBy]) ? parseFloat(a[orderBy].replace(/[^0-9.-]+/g, "")) : a[orderBy];
  const valueB = typeof b[orderBy] === 'string' && !isNaN(b[orderBy]) ? parseFloat(b[orderBy].replace(/[^0-9.-]+/g, "")) : b[orderBy];
  
  if (valueB < valueA) {
    return -1;
  }
  
  if (valueB > valueA) {
    return 1;
  }

  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function LabelwithSuperScript(label){
  let trulabel  = label.split('[')
  let superscript = trulabel[1]
  trulabel = trulabel[0]
  superscript = superscript.split(']')
  superscript =superscript[0]
  return(
    <>
    {trulabel} {' '}<sup style={{ fontSize: 8 }}>{ superscript}</sup>
    </>
  )
}
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    enableCheckbox
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {enableCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ verticalAlign: "bottom" }}
          >
            <TableSortLabel
              active={headCell.sorting}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              IconComponent={() =>
                orderBy === headCell.id && order === "asc" ? (
                  <ArrowDropUpIcon sx={{ order: 2 }} />
                ) : (
                  <ArrowDropDownIcon sx={{ order: 2 }} />
                )
              }
              sx={{ flexDirection: "row" }}
            >
              <Stack>
                {headCell.additionalHeaderLabel && (
                  <Typography
                    variant="tableColumnExtraHeaderLabel"
                    component={"div"}
                  >
                    {headCell.additionalHeaderLabel}
                  </Typography>
                )}
                <Typography variant="tableColumnHeaderLabel" component={"div"}>
                  {headCell.label.includes('[')?LabelwithSuperScript(headCell.label):headCell.label}
                </Typography>
              </Stack>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  headCells: PropTypes.array.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  enableCheckbox: PropTypes.bool,
};

function EnhancedTableToolbar(props) {
  const theme = useTheme();
  const { numSelected, tableTitle } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 0 },
        pr: { xs: 0, sm: 0 },
        mb: 2,
        mt: 0.2,
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
        padding: "0px",
        minHeight: "0px !important",
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%", color: theme?.customization?.themeColor === "dark" && '#fff' }}
          variant="tableHeader"
          id="tableTitle"
          component="div"
        >
          {tableTitle}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        ""
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  tableTitle: PropTypes.string,
};

export default function EnhancedTable(props) {
  const theme = useTheme();
  const tableRef = React.createRef();
  const {
    headCells,
    rows,
    enableCheckbox,
    tableTitle,
    enableToolBar,
    pagination,
    tableContainerHeight,
    loading,
    isSmallTable,
    tableRowCount,
    resetPagination,
    setResetPagination,
    sx,
    isSmallRows,
    isBigTable,
    handleCellClick,
    actionItems,
    statusShownByInfo
  } = props;
  const [rowsPerPage, setRowsPerPage] = React.useState(!isSmallTable ? tableRowCount ? tableRowCount : 5 : config.defaultRecordsperPage); // Initial rows per page

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("fat");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [dense, setDense] = React.useState(false);

  useEffect(() => {
    if (resetPagination) {
      setPage(1);
      setResetPagination(false);
    }
  }, [resetPagination])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    if (!enableCheckbox) {
      return false;
    }
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // tableRef.current.scrollTop = 0;
    // window.scrollTo(0, 0);
  };

  //   const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(parseInt(event.target.value, 10));
  //     setPage(0);
  //   };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        (page - 1) * rowsPerPage,
        page * rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );



  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 1 ? Math.max(0, rowsPerPage - visibleRows.length) : 0;

  const skeletonRows = Array.from({ length: rowsPerPage }, (_, i) => (
    <TableRow key={i}>
      {headCells.map((head, index) => (
        <TableCell key={index}>
          <Skeleton animation="wave" />
        </TableCell>
      ))}
    </TableRow>
  ));

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Reset to the first page when changing rows per page
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        {enableToolBar && (
          <EnhancedTableToolbar
            numSelected={selected.length}
            tableTitle={tableTitle}
          />
        )}
        {actionItems && <TableActionSection actionItems={actionItems} />}
        <TableContainer
          style={{
            maxHeight: tableContainerHeight ? tableContainerHeight : "auto",
            width: "100%",

          }}
          sx={{
            overflow: "overlay",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 5px grey",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#767572",
              borderRadius: "10px",
              "&:hover": {
                background: "#bdae60",
              },
            },
          }}
        >
          <Table
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            stickyHeader
            ref={tableRef}
            sx={sx}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
              enableCheckbox={enableCheckbox}
            />
            <TableBody>
              {loading && skeletonRows}
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.name)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name}
                    selected={isItemSelected}
                  >
                    {enableCheckbox && (
                      <TableCell padding="checkbox" key={index}>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                    )}
                    {Object.keys(row).map((key, index) => {
                      const align =
                        headCells.find((cell) => cell.id === key)?.align ||
                        "center";
                      if (!key.includes('notrequired')) {
                        return (
                          <TableCell key={index} align={align} sx={{ padding: isSmallRows ? '5px !important' : '' }}>
                            {key === "qty" || key === "market_value" ? (
                              <>
                                {Math.sign(row[key]) === -1 ? (
                                  <Typography
                                    sx={{
                                      ...theme.typography.redHighLightedText,
                                    }}
                                  >
                                    {row[key]}
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{
                                      ...theme.typography.greenHighLightedText,
                                    }}
                                  >
                                    {row[key]}
                                  </Typography>
                                )}
                              </>
                            ) : key === 'symbol' ? (
                              <div style={{ display: 'flex', gap: 10 }}>
                                {typeof row[key] !== 'string' && <Button variant="text" sx={{ p: 0 }} onClick={() => handleCellClick(row[key][0], row[key][1])}>
                                  {row[key][1] ? <KeyboardDoubleArrowRightIcon size="small" /> : <KeyboardDoubleArrowDownIcon />}
                                </Button>}
                                {row[key]}
                              </div>
                            ) : (key === 'account_alias_name' && row[key] !== null) ? (
                              <div>{row[key][0] ?? ''} <sup style={{ fontSize: 8 }}>{row[key][1] ?? ''}</sup></div>
                            ) : key === "status" || key === 'ticker_company' ? (
                              <>
                                {row[key] === "Submitted" ? (
                                  <Typography
                                    sx={{
                                      ...theme.typography.greenHighLightedText,
                                    }}
                                  >
                                    {row[key]}
                                  </Typography>
                                ) : (
                                  <>
                                    {statusShownByInfo ?
                                      <Box display={'flex'} alignItems={'center'}>
                                        <Typography>{row[key][0]}</Typography>
                                        <Tooltip title={row[key][1]} placement='top' sx={{ color: theme?.customization?.themeColor === 'dark' && '#fff' }}>
                                          <IconButton size='small' alignItems={'center'}>
                                            <sup><InfoOutlined sx={{ fontSize: 16 }} /></sup>
                                          </IconButton>
                                        </Tooltip>
                                      </Box> : <Typography>{row[key]}</Typography>
                                    }
                                  </>
                                )}
                              </>
                            ) : key === "ticker_symbol" ||
                              key === "ticker" ||
                              key === "invoiceNumber" ||
                              key === "tdaAccountAlias" ? (
                              <div
                                style={{
                                  color:
                                    theme?.customization?.themeColor === "dark"
                                      ? "#fff"
                                      : "#0C1E5B",
                                  fontWeight: 700,
                                  fontSize: "13px",
                                  letterSpacing: "0.26px",
                                }}
                              >
                                {row[key]}
                              </div>
                            ) : key === "invoicePaidStatus" ? (
                              <>
                                {row[key]["invoicePaidStatus"].toLowerCase() ===
                                  "paid" ? (
                                  <Stack direction="column">
                                    <Chip
                                      color="success"
                                      label={row[key]["invoicePaidStatus"]}
                                    />
                                    <Link
                                      href={row[key]["invoicePaymentLink"]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      color={theme?.customization?.themeColor === "dark" ? "#00BEFA" : "#000"}
                                      sx={{ mt: 1 }}
                                    >
                                      View
                                    </Link>
                                  </Stack>
                                ) : dayjs(row[key]["dueDate"], "yyyy-MM-dd") <
                                  dayjs(new Date(), "yyyy-MM-dd") ? (
                                  <Stack direction="column">
                                    <Chip
                                      color="warning"
                                      label={row[key]["invoicePaidStatus"]}
                                    />
                                    <Link
                                      href={row[key]["invoicePaymentLink"]}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      color={theme?.customization?.themeColor === "dark" ? "#00BEFA" : "#000"}
                                      sx={{ mt: 1 }}
                                    >
                                      Pay Now
                                    </Link>
                                  </Stack>
                                ) : (
                                  <Chip
                                    color="success"
                                    label={row[key]["invoicePaidStatus"]}
                                  />
                                )}
                              </>
                            ) : key === 'invoiceLink' ? (
                              <>
                                <Link
                                  href={row[key]}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  color={theme?.customization?.themeColor === "dark" ? "#00BEFA" : "#000"}
                                  sx={{ mt: 1 }}
                                >
                                  View
                                </Link>
                              </>
                            ) : key === 'is_account_healthy' ? (
                              <div style={{ paddingLeft: '70%' }}>
                                <div style={{ width: 15, height: 15, borderRadius: '50%', background: row[key] ===true? 'green' : 'red', textAlign: 'center' }} />
                              </div>
                            ) : key === 'recent_trade_date' ? (
                              <>
                                {statusShownByInfo ?
                                  <Box display={'flex'} justifyContent={'space-between'}>
                                    <Typography sx={{
                                      textAlign: 'right', width: '80%', top: '7px', position: 'relative'
                                    }} >{row[key][0]}</Typography>
                                    <Tooltip title={row[key][1]} placement='top' sx={{ color: theme?.customization?.themeColor === 'dark' && '#fff' }}>
                                      <IconButton size='small' alignItems={'left'}>
                                        <InfoOutlined />
                                      </IconButton>
                                    </Tooltip>
                                  </Box> : <Typography>{row[key]}</Typography>
                                }
                              </>
                            ) : (
                              <Typography sx={{ textAlign: align, fontWeight: key === 'decision' ? 700 : '', color: key === 'decision' ? row[key] === 'BUY' || (row[key] && String(row[key]).toLowerCase()) ==='bull' ? 'green !important' : 'red !important' : '' }}>{row[key]}</Typography>
                            )}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <div style={{ height: (dense ? 33 : 53) * emptyRows }}>

                </div>
                // <TableRow
                //   style={{
                //     height: (dense ? 33 : 53) * emptyRows,
                //   }}
                // >
                //   {console.log('emptyRows >>>>>>>>>>', emptyRows)}
                //   <TableCell colSpan={6} />
                // </TableRow>
              )}
              {visibleRows.length === 0 && !loading && (
                <>
                  {Array.from({ length: rowsPerPage }, (x, i) => (
                    <TableRow key={i}>
                      <TableCell
                        colSpan={headCells.length}
                        style={{
                          border: "unset",
                          textAlignLast: isMobile ? "left" : isBigTable ? "left" : "center",
                          fontSize: "22px",
                        }}
                      >
                        {Math.round((0 + rowsPerPage) / 2) - 1 === i && (
                          <span style={{ color: theme?.customization?.themeColor === "dark" && '#fff' }}>No Records Found</span>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 4 }}
        >
          {pagination !== false && !loading && rows.length > rowsPerPage && (
            <Pagination
              count={Math.ceil(rows.length / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              defaultPage={1}
              renderItem={(item) => (
                <PaginationItem
                  slots={{
                    previous: IconChevronsLeft,
                    next: IconChevronsRight,
                  }}
                  sx={{ display: visibleRows.length !== 0 ? "block" : "none" }}
                  {...item}
                />
              )}
            />
          )}
          {!isSmallTable && visibleRows.length !== 0 && (
            <RowsPerPageSelector
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Stack>

      </Paper>
    </Box>
  );
}
