import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton"; // Import IconButton
import CloseIcon from "@mui/icons-material/Close"; // Import Close icon
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "650px",
  width: "80%",
  overflow: "scroll",
};

export default function TermsCondition({ isOpen, handleModel }) {
  const [open, setOpen] = React.useState(isOpen);
  const handleClose = () => {
    handleModel();
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <Stack sx={{ mb: 2 }}>
              <Typography variant="privacyPolicyTitle">
              Terms and Conditions
              </Typography>
              <IconButton
                aria-label="close"
                color="#5C6E9A"
                size="small"
                onClick={handleClose} // Add onClick handler to close the modal
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  backgroundColor: "transparent",
                  color: "#000",
                  m: 2,
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack>
              <Typography variant="privacyPolicyContent">
                <div class="elementor-widget-container">
                  <p>
                    <strong>
                      Terms and Conditions for Algorithmic Trading Platform
                    </strong>
                  </p>
                  <p>
                    Welcome to
                    <a href="https://quantumtrades.com/">Quantum Trades</a>
                    Algorithmic Trading Platform. These Terms and Conditions
                    govern your use of the Platform, so please read them
                    carefully before using our services.
                  </p>
                  <p>
                    <strong>Acceptance of Terms</strong>
                    <br />
                    By accessing or using our Platform, you agree to these Terms
                    and Conditions and our Privacy Policy. If you do not agree
                    to these terms, do not use the Platform.
                  </p>
                  <p class="nitro-offscreen">
                    <strong>Description of Services</strong>
                    <br />
                    Our Platform provides automated trading services for digital
                    assets. We do not provide financial advice, and you are
                    solely responsible for making your own trading decisions.
                  </p>
                  <p class="nitro-offscreen">
                    <strong>Registration and Accounts</strong>
                    <br />
                    In order to use our Platform, you must register for an
                    account. You agree to provide accurate and complete
                    information when registering and to keep your account
                    information up-to-date. You are responsible for maintaining
                    the security of your account and password, and you agree not
                    to share your password with anyone else.
                  </p>
                  <p class="nitro-offscreen">
                    <strong>Fees and Payment</strong>
                    <br />
                    We may charge fees for using our Platform, and we reserve
                    the right to change our fees at any time. You agree to pay
                    all fees and charges associated with your use of the
                    Platform.
                  </p>
                  <p class="nitro-offscreen">
                    <strong>Intellectual Property</strong>
                    <br />
                    All content and materials on our Platform, including but not
                    limited to logos, trademarks, and software, are owned or
                    licensed by us and are protected by copyright and other
                    intellectual property laws. You may not use our content and
                    materials without our prior written consent.
                  </p>
                  <p class="nitro-offscreen">
                    <strong>Prohibited Conduct</strong>
                    <br />
                    You agree not to:
                  </p>
                  <p class="nitro-offscreen">
                    Use our Platform for any unlawful purpose
                    <br />
                    Interfere with or disrupt our Platform or servers
                    <br />
                    Attempt to gain unauthorized access to our Platform or
                    servers
                    <br />
                    Engage in any activity that could damage, disable, or impair
                    our Platform or servers
                    <br />
                    Copy, reproduce, or distribute our content and materials
                    without our prior written consent
                    <br />
                    Limitation of Liability
                    <br />
                    We are not liable for any damages arising out of your use of
                    our Platform, including but not limited to loss of profits
                    or data, even if we have been advised of the possibility of
                    such damages.
                  </p>
                  <p class="nitro-offscreen">
                    <strong>Indemnification</strong>
                    <br />
                    You agree to indemnify and hold us harmless from any claims,
                    damages, or expenses arising out of your use of our Platform
                    or violation of these Terms and Conditions.
                  </p>
                  <p class="nitro-offscreen">
                    <strong>Termination</strong>
                    <br />
                    We reserve the right to terminate your account and access to
                    our Platform at any time for any reason.
                  </p>
                  <p class="nitro-offscreen">
                    <strong>Changes to Terms</strong>
                    <br />
                    We may update these Terms and Conditions from time to time.
                    We will notify you of any changes by posting the new Terms
                    and Conditions on this page.
                  </p>
                  <p class="nitro-offscreen">
                    <strong>Governing Law</strong>
                    <br />
                    These Terms and Conditions are governed by the laws of
                    United States, and any disputes arising out of these Terms
                    and Conditions shall be resolved in United States courts.
                  </p>
                  <p class="nitro-offscreen">
                    If you have any questions or concerns about these Terms and
                    Conditions, please contact us at&nbsp;
                    <a href="mailto:Support@QuantumTrades.com">
                      Support@QuantumTrades.com
                    </a>
                  </p>{" "}
                </div>
              </Typography>
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
