import React from "react";
import { MenuItem, Stack, InputLabel, Select, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SelectElement from "ui-component/Select";

const MenuItemWrapper = styled(MenuItem)(({ theme, cardColor }) => ({
  color: theme?.customization?.themeColor === "dark" && "#fff",
  "&:hover": {
    background: theme?.customization?.themeColor === "dark" && "#00BEFA",
  },
}));

const RowsPerPageSelector = ({ rowsPerPage, handleChangeRowsPerPage }) => {
  const theme = useTheme();

  const selectItems = [
    { value: 3, label: 3 },
    { value: 5, label: 5 },
    { value: 10, label: 10 },
    { value: 15, label: 15 },
    { value: 20, label: 20 },
    { value: 25, label: 25 },
    { value: 30, label: 30 },
    { value: 35, label: 35 },
    { value: 40, label: 40 },
    { value: 45, label: 45 },
    { value: 50, label: 50 },
  ];
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      <InputLabel
        sx={{ color: theme?.customization?.themeColor === "dark" && "#fff" }}
      >
        Records per page:{" "}
      </InputLabel>
      <Select
        value={rowsPerPage}
        onChange={handleChangeRowsPerPage}
        sx={{
          ".MuiInputBase-input": {
            color: theme?.customization?.themeColor === "dark" && "#fff",
            "&.Mui-selected ": {
              background:
                theme?.customization?.themeColor === "dark" && "#00BEFA",
            },
          },
          svg: {
            color: theme?.customization?.themeColor === "dark" && "#fff",
          },
        }}
      >
        <MenuItemWrapper value={3}>3</MenuItemWrapper>
        <MenuItemWrapper value={5}>5</MenuItemWrapper>
        <MenuItemWrapper value={10}>10</MenuItemWrapper>
        <MenuItemWrapper value={15}>15</MenuItemWrapper>
        <MenuItemWrapper value={20}>20</MenuItemWrapper>
        <MenuItemWrapper value={25}>25</MenuItemWrapper>
        <MenuItemWrapper value={30}>30</MenuItemWrapper>
        <MenuItemWrapper value={35}>35</MenuItemWrapper>
        <MenuItemWrapper value={40}>40</MenuItemWrapper>
        <MenuItemWrapper value={45}>45</MenuItemWrapper>
        <MenuItemWrapper value={50}>50</MenuItemWrapper>
      </Select>
    </Stack>
  );
};

export default RowsPerPageSelector;
