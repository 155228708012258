import AxiosAPI from '../helpers/axiosApi';

// Existing action types
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_THEME_COLOR = '@customization/SET_THEME_COLOR';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_USER_ACCOUNT_LIST = '@customization/SET_USER_ACCOUNT_LIST';
export const SET_REDIRECT_ALLOW_ENABLED = '@customization/SET_REDIRECT_ALLOW_ENABLED';
export const SET_USER_SESSION_DETAILS = '@customization/SET_USER_SESSION_DETAILS';
export const SET_COUNTRIES_LIST = '@customization/SET_COUNTRIES_LIST';
export const SET_USER_ACCOUNT_DROPDOWNL_LIST = '@customization/SET_USER_ACCOUNT_DROPDOWNL_LIST';

// RevisePreAuthTickers action types
export const SET_STOCK_DETAILS = '@customization/SET_STOCK_DETAILS';
export const SET_SAVED_TICKERS = '@customization/SET_SAVED_TICKERS';
export const UPDATE_SELECTED_TICKERS = '@customization/UPDATE_SELECTED_TICKERS';
export const SET_ERROR = '@customization/SET_ERROR';

// Current Trade Plan action types
export const GET_ACCOUNT_DATA = '@customization/GET_ACCOUNT_DATA';
export const GET_ENABLE_LIVE_TRADE = '@customization/GET_ENABLE_LIVE_TRADE';
export const GET_STRATEGY_RULE = '@customization/GET_STRATEGY_RULE';
export const PUT_ENABLE_LIVE_TRADE = '@customization/PUT_ENABLE_LIVE_TRADE';
export const PUT_HIGH_LEVEL_TRADE_PLAN = '@customization/PUT_HIGH_LEVEL_TRADE_PLAN';



// Action creators for RevisePreAuthTickers
export const setStockDetails = (stockDetails) => ({
  type: SET_STOCK_DETAILS,
  payload: stockDetails,
});

export const setSavedTickers = (savedTickers) => ({
  type: SET_SAVED_TICKERS,
  payload: savedTickers,
});

export const updateSelectedTickers = (selectedTickers) => ({
  type: UPDATE_SELECTED_TICKERS,
  payload: selectedTickers,
});

export const setError = (error) => ({
  type: SET_ERROR,
  payload: error,
});

// Async actions for RevisePreAuthTickers
