import axios from 'axios';
const axiosInstance = axios.create();


axiosInstance.interceptors.request.use(config => {
  const cookies = document.cookie;
  config.headers['Cookie'] = cookies;
  return config;
});

const AxiosAPI = {
	callAPI: (url, method, data, header, responseType, dirrectSub) => {
        let subscriptionKey = url.indexOf(process.env.REACT_APP_NEW_API_URL) !== -1 ? process.env.REACT_APP_NEW_SUBSCRIPTION_KEY : process.env.REACT_APP_OLD_SUBSCRIPTION_KEY;

		if (method === 'get') {
			header = {
				"Ocp-Apim-Subscription-Key": dirrectSub ? dirrectSub : subscriptionKey,
				credentials: true
			}
			try {
				return new Promise(async (resolve, reject) => {
					await axios({
						method: method,
						url: url,
						headers: header,
						responseType: responseType,
						withCredentials: true
					}).catch(err => {
						if (err.response && err.response.status === 404) {
                            console.log('Oops!! Session expried or Not Authorized 1111');
						}
						if (err.response && err.response.status === 400) {
                            console.log('Invalid call')
						}
						if (err.response && err.response.status === 500) {
                            console.log("Server Error")
						}
						resolve(err.response);
						// throw err;
					}).then(response => {
						if (response != undefined) {
							let data = response.data;
							resolve(data)
						} else {
							resolve(response);

						}
					}).catch(function (err) {
						if (err.response && err.response.status === 404) {
                            console.log('Oops!! Session expried or Not Authorized')
						}
						// throw err;
					});
				});
			} catch (err) {
				console.log('There has been a problem with fetch operation: ', err);
			}
		}

		if (method === "post" || method === "put") {
			try {
				return new Promise(async (resolve, reject) => {
					await axiosInstance({
						method: method,
						url: url,
						data: data,
						headers: {
							"Content-Type": header,
							"Ocp-Apim-Subscription-Key": subscriptionKey,
							credentials: true
						},
						responseType: responseType,
						withCredentials : true
					})
						.then(function (response) {
							let data = response.data;
							resolve(data)
						})
						.catch(function (err) {
							if (err.response && err.response.status === 404) {
                                console.log('Oops!! Session expried or Not Authorized')
							}else{
								resolve(err.response.data)
							}
							// throw err;
						});
				});
			} catch (err) {
				console.log('There has been a problem with fetch operation: ', err);
			}
		}
	}
}

export default AxiosAPI;
