import { Paper, Typography } from "@mui/material";
import { memo } from "react";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";

const MarkdownParser = (props) => {
  const themeColor = useSelector((state) => state.customization.themeColor)

  return (
    <Paper sx={{p:2, pb: 2, borderRadius: 2 }} >
      <Typography variant="body2" sx={{color: themeColor === 'dark' ? "#fff" : '#364152'}}>
        <ReactMarkdown>{props.message}</ReactMarkdown>
      </Typography>
    </Paper>
  )
};

export default memo(MarkdownParser);
