//import { useTheme } from '@mui/material/styles';
import { useTheme } from "@mui/material/styles";

import logo from 'assets/images/QT_logo_147_57.png'
import logo_inverse from 'assets/images/QT_logo_w_147x57.png'


// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const theme = useTheme();

  return (
    
    <img src={theme?.customization?.themeColor === "dark" ? logo_inverse : logo} alt="Quantum Trades" width="auto" />
  );
};

export default Logo;
