import { useTheme } from '@emotion/react';
import { Grid, Button, Paper } from '@mui/material';
import MuiTypography from '@mui/material/Typography';
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router';

const General = (props) => {
  const theme = useTheme();
  const navigate = useNavigate()
  const pathId = useLocation().hash.slice(1);

  useEffect(()=>{
    if(parseInt(pathId) === 1) navigate('/billing')
  },[])

  return (
    <Paper sx={{m: 0, p: 0, border: 'solid'}}>
      <Grid container spacing={2} sx={{m:1, pb:4, color: theme.palette.text, textWrap: 'wrap', width: '97.5%', overflow: 'hidden'}} columns={{ xxs:2, xs: 2, sm: 12, md: 12, lg: 12, xl:12 }}>
        <Grid item xxs={2} xs={2} sm={12} md={12} lg={12} xl={12}>
          <MuiTypography variant="h1" gutterBottom>
          Information
          </MuiTypography>
        </Grid> 
        <Grid item xs={1} sm={3} md={3} lg={3} xl={3} > <MuiTypography variant="h5" gutterBottom>Subscription Status: </MuiTypography></Grid>
        <Grid item xs={1} sm={9} md={9} lg={9} xl={9}><MuiTypography variant="h5" gutterBottom>{props.subStatus === 'PAID' ? 'Active' : 'Inactive'}</MuiTypography></Grid>
        <Grid item xs={1} sm={3} md={3} lg={3} xl={3} ><MuiTypography variant="h5" gutterBottom>Subscription plan: </MuiTypography></Grid>
        <Grid item xs={1} sm={9} md={9} lg={9} xl={9} sx={{textWrap: 'wrap'}}><MuiTypography variant="h5" gutterBottom>
          { props.subPlanName ?? 'Not Subscribed'}
          {!props.subPlanName && 
            <Button 
              size='small' 
              variant='outlined' 
              sx={{ml: 1}} 
              onClick={()=>props.setValue(1)}
            >
              Subscribe
            </Button>
          }
          </MuiTypography>
        </Grid>
        <Grid item xs={1} sm={3} md={3} lg={3} xl={3} ><MuiTypography variant="h5" gutterBottom>Subscription Details: </MuiTypography></Grid>
        <Grid item xs={1} sm={9} md={9} lg={9} xl={9}><MuiTypography variant="h5" gutterBottom>{ props.subPlanDescription ? !props.subPlanDescription.includes('undefined') ?  props.subPlanDescription : '-' : '-'} </MuiTypography></Grid>                
        {/* {props.subStatus && <Grid item xs={2} sm={2} md={12} lg={12} xl={12}><Button size="small" variant="outlined">Cancel</Button></Grid>} */}
        <Grid item xxs={2} xs={2} sm={3} md={3} lg={3} xl={3}><MuiTypography variant="h5" gutterBottom>Billed period: </MuiTypography></Grid>
        <Grid item xs={1} sm={9} md={9} lg={9} xl={9}><MuiTypography variant="h5" gutterBottom>{props.billingPeriod.includes('undefined') ? '-' : props.billingPeriod ?? '-'} </MuiTypography></Grid>
        <Grid item xs={1} sm={3} md={3} lg={3} xl={3}><MuiTypography variant="h5" gutterBottom>Current period  end date: </MuiTypography></Grid>
        <Grid item xs={1} sm={9} md={9} lg={9} xl={9}><MuiTypography variant="h5" gutterBottom>{props.currPeriodEndDate ?? '-'} </MuiTypography></Grid>
      </Grid>  
    </Paper>
  )
}

export default General