import { useTheme } from '@emotion/react';
import { Box, Paper, Typography } from '@mui/material'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Success() {
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => { 
   // Redirect to the success page
   navigate('/billing/success');

    // Clean up after 5 seconds and redirect to the billing page
    const timeout = setTimeout(() => {
        navigate('/billing');
    }, 7500);

    // Clear the timeout to prevent memory leaks
    return () => clearTimeout(timeout);
  }, [navigate]);

  return (
    <Paper>
        <Box sx={{width: '100%', height: 500, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: theme.palette.text}}>
            <svg version="1.1" id="Capa_1" viewBox="0 0 50 50" width={150} height={150}>
                <circle style={{fill:"#25AE88"}} cx="25" cy="25" r="25"/>
                <polyline style={{fill: 'none', stroke: '#fff', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10}} points="38,15 22,33 12,25 "/>
            </svg>
            <Typography variant='h2' sx={{color: '#25AE88', mt: 2}}>Success</Typography>
            <Typography variant='body1' >Your payment has been processed successfully.</Typography>
            <Typography variant='body1' sx={{color: 'gray !important', mt:10}}>Redirecting to billing page ...</Typography>
        </Box>
    </Paper>
  )
}

export default Success