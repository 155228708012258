import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton"; // Import IconButton
import CloseIcon from "@mui/icons-material/Close"; // Import Close icon
import { isMobile } from "react-device-detect";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import { startOfMonth, addMonths, format } from 'date-fns';
export default function ConfirmationDialog(props) {
    //   const [open, setOpen] = React.useState(isOpen);
    //   const handleClose = () =>{
    //     handleModel()
    //     setOpen(false)
    // };
    const theme = useTheme();
    const formattedDate = new Date();
    return (
        <Dialog
            // sx={{ "& .MuiDialog-paper": { width: "35%", height: 200 } }}
            maxWidth="s"
            open={props.open}
        >
            <DialogTitle>
                <Typography variant="h2"
                    sx={{
                        color: theme?.customization?.themeColor === "dark" ? '#fff' : theme.heading
                    }}>Confirmation</Typography>
            </DialogTitle>
            <DialogContent>
                {props.page === 'subscription' ?
                    <>
                        <Typography variant="h3" sx={{
                            color: theme?.customization?.themeColor === "dark" ? '#fff' : theme.heading,
                        }}>
                            This Plan will be effective from {format(startOfMonth(addMonths(new Date(), 1)), "MM-dd-yyyy")}.
                        </Typography>
                        <Typography variant="h3" sx={{
                            color: theme?.customization?.themeColor === "dark" ? '#fff' : theme.heading,
                        }}>
                            Are you sure , you want to {props.action} the current plan?
                        </Typography>
                    </>
                    : 
                        <Typography variant="h3" sx={{
                            color: theme?.customization?.themeColor === "dark" ? '#fff' : theme.heading,
                        }}>
                            {props.content}
                        </Typography>
                        
                }
            </DialogContent>
            <DialogActions >
                <div style={{
                    padding: '20px 24px'
                }}>
                    <Button sx={{ ...theme.buttons.secondaryButton, width: 'max-content' }} autoFocus onClick={props.handleCancel} color="inherit">
                        {props.buttonText !== undefined? props.buttonText.no:"No"}
                    </Button>&nbsp;&nbsp;
                    <Button sx={{ ...theme.buttons.primaryButton, width: 'max-content' }} onClick={props.handleOk} color="primary">{props.buttonText !== undefined? props.buttonText.yes:"Yes"}</Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}
