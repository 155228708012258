/* eslint-disable react/prop-types */
import { getBotResponse } from "commonApi";
import React from "react";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {

  const generateResponse = async (message) => {
    let botMessage;

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));

    // Add Loading before API call
    const loading = createChatBotMessage('Loading...')
    setState((prev) => ({ ...prev, messages: [...prev.messages, loading], }))


    try {
      let thread_id = sessionStorage.getItem('thread_id')
      const formValues = new FormData();
      formValues.append('thread_id', thread_id)
      formValues.append('question', message)

      const geminiResponse = await getBotResponse(formValues)
      // const geminiResponse = await fetch(
      //   `http://127.0.0.1:8000/generate-response?question=${message}`,
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //   }
      // ).then((res) => res.json());

      if (geminiResponse && geminiResponse.response) {
        botMessage = createChatBotMessage(geminiResponse.response);
      } else {
        botMessage = createChatBotMessage(
          "Some error occurred, please try again later."
        );
      }
    } catch (error) {
      console.error(error);
      botMessage = createChatBotMessage(
        "Some error occurred, please try again later."
      );
    }

    // Stop Loading after call is returned
    setState((prev) => {
      // Remove Loading here
      const newPrevMsg = prev.messages.slice(0, -1)
      return { ...prev, messages: [...newPrevMsg, botMessage], }
    })
  };

  // Put the generateResponse function in the actions object to pass to the MessageParser
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            generateResponse,
          },
        });
      })}
    </div>
  );
};
export default ActionProvider;
