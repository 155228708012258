import { createTheme } from '@mui/material/styles';

// assets
import colors from 'assets/scss/_themes-vars.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeButtons from './buttons';
import themeTypography from './typography';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization) => {
  const color = colors;

  const darkModeTheme = {
    mainBackground: '#272E34',
    sectionBackground: '#313842',
    whiteFont: '#fff',
    blueFont: '#93E5FF',
    tableLabelBackground: '#5C687A'
  };

  const themeOption = {
    colors: color,
    heading: color.grey900,
    paper: color.paper,
    backgroundDefault: color.paper,
    background: color.primaryLight,
    darkTextPrimary: color.grey700,
    darkTextSecondary: color.grey500,
    textDark: color.grey900,
    menuGroupName: color.grey20,
    menuSelected: color.paper,
    menuSelectedBack: color.primaryMain,
    primaryMain: color.primaryMain,
    disabledButton: color.disabledButton,
    menuText: color.grey600,
    divider: color.grey200,
    darkHeaderText: color.darkHeaderText,
    highlightedAmount: color.highlightedAmount,
    messageText: color.messageText,
    hightlightedMessageRed: color.hightlightedMessageRed,
    lightBlueBackground: color.lightBlueBackground,
    lightDotBlueBackground: color.lightDotBlueBackground,
    darkDotBlueBackground: color.darkDotBlueBackground,
    silverBackground: color.silverBackground,
    goldBackground: color.goldBackground,
    platinumBackground: color.platinumBackground,
    institutionBackground: color.institutionBackground,
    primaryTextColor: color.primaryTextColor,
    darkMode: darkModeTheme,
    customization
  };

  const themeOptions = {
    direction: 'ltr',
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: '48px',
        padding: '16px',
        '@media (min-width: 600px)': {
          minHeight: '48px'
        }
      }
    },
    buttons: themeButtons(themeOption),
    customization: customization,
    darkMode: darkModeTheme,
    typography: themeTypography(themeOption),
    breakpoints: {
      values: {
        xxs: 510,
        xs: 658,   // Extra small devices (phones)
        sm: 768, // Small devices (tablets)
        md: 1100, // Medium devices (desktops)
        lg: 1800, // Large devices (large desktops)
        xl: 2100
      },
    },
  };

  const themes = createTheme(themeOptions);
  themes.components = componentStyleOverrides(themeOption);

  return themes;
};

export default theme;
