// assets
import {
  IconLayoutDashboard,
  IconLayoutKanban,
  IconServer2,
  IconSubtask,
  IconSettings,
  IconTimeline,
} from "@tabler/icons";
import { CanAccess } from "constants/Common";

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const mainMenu = {
  id: "dashboard",
  title: "Main Menu",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/dashboard",
      icon: IconLayoutDashboard,
      breadcrumbs: false,
      roles: { ...CanAccess, supportAdmin: false },
    },
    {
      id: "consolidated-dashboard",
      title: "Consolidated",
      type: "item",
      url: "/consolidated-dashboard",
      icon: IconLayoutDashboard,
      breadcrumbs: true,
      roles: { ...CanAccess, supportAdmin: false },
    },
    {
      id: "portfolio",
      title: "Portfolio",
      type: "item",
      url: "/portfolio",
      icon: IconLayoutKanban,
      breadcrumbs: true,
      roles: { ...CanAccess, supportAdmin: false },
    },
    {
      id: "options-activities",
      title: "Options Activities",
      type: "item",
      url: "/options-activities",
      icon: IconServer2,
      breadcrumbs: true,
      roles: { ...CanAccess },
    },
    {
      id: 'manage-portfolio',
      title: 'Manage Portfolio v2',
      type: 'item',
      url: '/manage-portfolio-v2',
      icon: IconSettings,
      breadcrumbs: true,
      roles: { ...CanAccess, supportAdmin: false },
    },
    {
      id: "manage-portfolio-new",
      title: "Manage Portfolio Strategy",
      type: "item",
      url: "/manage-portfolio-new",
      icon: IconSettings,
      breadcrumbs: true,
      roles: { ...CanAccess, supportAdmin: false },
    },
    {
      id: "balance-history",
      title: "Balance History",
      type: "item",
      url: "/balance-history",
      icon: IconTimeline,
      breadcrumbs: true,
      roles: { ...CanAccess, supportAdmin: false },
    },
    {
      id: "account-management",
      title: "Account Management",
      type: "item",
      url: "/account-management",
      icon: IconTimeline,
      breadcrumbs: true,
      roles: { ...CanAccess, supportAdmin: false },
    },
    {
      id: "qt-selection",
      title: "Qt Screener",
      type: "item",
      url: "/qt-selection",
      icon: IconTimeline,
      breadcrumbs: true,
      roles: { ...CanAccess, supportAdmin: false },
    },
    {
      id: "strategy-based-allocation",
      title: "Strategy Based Allocation",
      type: "item",
      url: "/strategy-based-allocation",
      icon: IconSettings,
      breadcrumbs: true,
      roles: { ...CanAccess, supportAdmin: false },
    },
  ],
};

export default mainMenu;
