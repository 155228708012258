import config from "config";
import * as actionTypes from "./actions";

export const initialState = {
  isOpen: [],
  defaultId: "dashboard",
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  opened: true,
  themeColor: config.themeColor,
  userAccountDetailsList: config.userAccountDetailsList,
  profileInfo: {},
  // RevisePreAuthTickers state
  stockDetails: [],
  savedTickers: [],
  selectedTickers: [],
  // Current Trade Plan state
  getAccountDetails: [],
  getEnableLiveTradeDetails: [],
  strategyRuleDetails: [],
  selectedAccount: {},
  currentAccountId: '',
  error: null,
};

const customizationReducer = (state = initialState, action) => {
  let id;
  switch (action.type) {
    case actionTypes.MENU_OPEN:
      id = action.id;
      return {
        ...state,
        isOpen: [id],
      };
    case actionTypes.SET_MENU:
      return {
        ...state,
        opened: action.opened,
      };
    case actionTypes.SET_FONT_FAMILY:
      return {
        ...state,
        fontFamily: action.fontFamily,
      };
    case actionTypes.SET_THEME_COLOR:
      return {
        ...state,
        themeColor: action.themeColor,
      };
    case actionTypes.SET_BORDER_RADIUS:
      return {
        ...state,
        borderRadius: action.borderRadius,
      };
    case actionTypes.SET_USER_ACCOUNT_LIST:
      return {
        ...state,
        userAccountDetailsList: action.userAccountDetailsList,
      };
    case actionTypes.SET_REDIRECT_ALLOW_ENABLED:
      return {
        ...state,
        isRedirectAlertEnabled: action.isRedirectAlertEnabled,
      };
    case actionTypes.SET_USER_SESSION_DETAILS:
      return {
        ...state,
        profileInfo: action.profileInfo,
      };
    case actionTypes.SET_COUNTRIES_LIST:
      return {
        ...state,
        countriesList: action.countriesList,
      };
    case actionTypes.SET_USER_ACCOUNT_DROPDOWNL_LIST:
      return {
        ...state,
        userAccountDropdownList: action.userAccountDropdownList,
      };

    case actionTypes.SET_STOCK_DETAILS:
      return {
        ...state,
        stockDetails: action.payload,
      };
    case actionTypes.SET_SAVED_TICKERS:
      return {
        ...state,
        savedTickers: action.payload,
      };
    case actionTypes.UPDATE_SELECTED_TICKERS:
      return {
        ...state,
        selectedTickers: action.payload,
      };
    // Current Trade Plan cases
    case actionTypes.GET_ACCOUNT_DATA:
      return {
        ...state,
        getAccountDetails: action.payload,
      };
    case actionTypes.GET_ENABLE_LIVE_TRADE:
      return {
        ...state,
        getEnableLiveTradeDetails: action.payload,
      };
    case actionTypes.GET_STRATEGY_RULE:
      return {
        ...state,
        strategyRuleDetails: action.payload,
      };
    case actionTypes.PUT_ENABLE_LIVE_TRADE:
      return {
        ...state,
        getEnableLiveTradeDetails: [action.payload],
      };
    case actionTypes.PUT_HIGH_LEVEL_TRADE_PLAN:
      return {
        ...state,
        getEnableLiveTradeDetails: [action.payload],
      };
    case actionTypes.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default customizationReducer;