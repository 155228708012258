import { useState, useLayoutEffect } from "react";
import { useCookies } from "react-cookie";
import PropTypes from "prop-types";
import { forwardRef } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Stack,
} from "@mui/material";
import { getAccounts } from "commonApi/index";
import SelectElement from "ui-component/Select";
import { SET_USER_ACCOUNT_LIST } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import Popover from "@mui/material/Popover";
import ErrorIcon from "@mui/icons-material/Error";
import { IconEye } from "@tabler/icons";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// constant
const headerInnerSX = {
  "& .MuiCardHeader-action": {
    mr: 0,
    mt: 0,
    width: { lg: "340px", md: "340px", xs: "auto" },
  },
  "& .MuiCardHeader-content": {
    paddingBottom: { xs: "5px", sm: "5px" },
    paddingTop: { sm: "5px" },
  },
  display: { lg: "flex", md: "flex", xs: "block" },
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentClass = "",
      contentSX = {},
      darkTitle,
      secondary,
      superAdmin,
      shadow,
      sx = {},
      headerSX = {},
      title,
      pageDescription,
      viewTickerSection,
      handleViewTickerOpen,
      viewInfo,
      handelInfoClick,
      ...others
    },
    ref
  ) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const accountSelectedPre = useSelector(
      (state) => state.customization.userAccountDetailsList.selectedAccount
    );
    const [accountDetailsList, setAccountDetailsList] = useState(null);
    const [accountDetails, setAccountDetails] = useState(null);
    const [cookies, setCookie] = useCookies(["login_session"]);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    useLayoutEffect(() => {
      // fetchData();
    }, [superAdmin]);

    const fetchData = async (selectedAccount) => {
      let accountList = await getAccounts(
        superAdmin ? "" : cookies.login_session.email
      );
      if (accountList.length > 0) {
        const accountDropDown = [];
        accountList.forEach((element, index, array) => {
          accountDropDown.push({
            ...element,
            label: element.account_alias_name,
            id: element.account_id,
          });
        });
        //if (selectedAccount) {
        let accountDetailsArr = [];
        const filterAcc = accountDropDown.filter(
          (e) =>
            e.account_id ===
            (selectedAccount
              ? selectedAccount
              : accountSelectedPre && accountSelectedPre.account_id
              ? accountSelectedPre.account_id
              : accountDropDown[0].account_id)
        );
        setAccountDetails(filterAcc[0]);
        dispatch({
          type: SET_USER_ACCOUNT_LIST,
          userAccountDetailsList: {
            accountList: accountDropDown,
            selectedAccount: filterAcc[0],
          },
        });
        setAccountDetailsList(accountDropDown);
      } else {
        dispatch({
          type: SET_USER_ACCOUNT_LIST,
          userAccountDetailsList: { accountList: [], selectedAccount: null },
        });
        setAccountDetails([]);
      }
    };

    const handleAccountChange = (event, newValue) => {
      const selectedAcount = newValue.account_id;
      let accountDetailsArr = [];
      if (accountDetailsList.length > 0) {
        const filter = accountDetailsList.filter(
          (e) => e.account_id === newValue.account_id
        );
        accountDetailsArr = filter[0];
        // fetchData(selectedAcount);
      }
      setAccountDetails(accountDetailsArr);
    };

    const accountDropDown = (
      <SelectElement
        className={"primaryAccountDropdown"}
        itemList={accountDetailsList ? accountDetailsList : []}
        label={"Account"}
        handleChange={handleAccountChange}
        defaultValue={accountDetails}
        width={"100%"}
      />
    );

    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          border: border ? "1px solid" : "none",
          borderColor: theme.palette.primary[200] + 25,
          ":hover": {
            boxShadow: boxShadow
              ? shadow || "0 2px 14px 0 rgb(32 40 45 / 8%)"
              : "inherit",
          },
          height: "100%",
          ...sx,
        }}
      >
        {/* card header and action */}
        {title && (
          <CardHeader
            sx={{ ...headerInnerSX, ...headerSX }}
            title={
              <Typography
                variant="pageHeaderText"
                sx={{
                  fontSize: { lg: "32px", md: "24px", xs: "20px" },
                  display: "flex",
                }}
              >
                {title} 
                {
                  viewInfo && 
                  (<InfoOutlinedIcon 
                  color="#00BEFA"
                  size="1rem"
                  style={{ cursor: "pointer", justifyContent:"center", marginLeft:5 }}
                  onClick={()=>handelInfoClick()} 
                  
                  />)
                }
                {viewTickerSection && 
                  <Stack>
                    <Typography sx={{ ml: 2, fontSize: '1rem', verticalAlign: 'middle' }}>
                      {" "}
                      View Ticker Selections{" "}
                      <IconEye stroke={2} size="1rem" sx={{verticalAlign: 'middle', cursor: 'pointer'}} onClick={handleViewTickerOpen} />
                    </Typography>
                  </Stack>
                }
                {pageDescription && pageDescription !== "" && (
                  <Stack
                    style={{ marginLeft: "8px" }}
                    className="mobileDescriptionView"
                  >
                    <ErrorIcon
                      onClick={handleClick}
                      style={{ color: "#00befa" }}
                    />
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Typography sx={{ p: 2 }}>{pageDescription}</Typography>
                    </Popover>
                  </Stack>
                )}
              </Typography>
            }
            action={secondary}
          />
        )}

        {pageDescription && pageDescription !== "" && (
          <Typography
            className="browserDescriptionView"
            sx={{
              color: "#0C1E5B",
              mt: 2,
              color:
                theme?.customization?.themeColor === "dark"
                  ? "#fff"
                  : "#0C1E5B",
            }}
          >
            {pageDescription}
          </Typography>
        )}

        {/* content & header divider */}
        {/* {title && <Divider />} */}

        {/* card content */}
        {content && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    );
  }
);

MainCard.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  children: PropTypes.node,
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  contentSX: PropTypes.object,
  darkTitle: PropTypes.bool,
  secondary: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
  superAdmin: PropTypes.bool,
  shadow: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default MainCard;
