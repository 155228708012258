import React,{useState} from "react";

import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, ButtonBase,IconButton, Menu,Typography,MenuItem,Grid,OutlinedInput } from "@mui/material";

// project imports
import LogoSection from "../LogoSection";
//import SearchSection from './SearchSection';
import ProfileSection from "./ProfileSection";
// import NotificationSection from './NotificationSection';
// import SettingSection from './SettingSection';
// import HelpSection from './HelpSection';
import AccountDropDownSection from "./AccountDropDownSection";
// assets
import { IconChevronLeft, IconChevronRight, IconMenu2 } from "@tabler/icons";
import CountriesList from "helpers/Countries";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SelectEle from "ui-component/SelectElement";
import Input from "ui-component/Input";
import { Button } from "rsuite";
import HeaderFilter from "ui-component/headerFilter";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle, leftDrawerOpened }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null)
  const columns = [ 
    { label: "Account alias name", id: "account_alias_name" }, 
    { label: "Account alias number", id: "account_alias_number" }, 
    { label: "Account balance", id: "account_balance" },
    { label: "Account category", id: "account_category" },
    { label: "Brokerage account type", id: "brokerage_account_type" },
    { label: "Brokerage business type", id: "brokerage_business_type" },
    { label: "Brokerage name", id: "brokerage_name" },
    { label: "Brokerage trading type", id: "brokerage_trading_type" },
    { label: "Date of joining", id: "date_of_joining" },
    { label: "Early exit profit percentage", id: "early_exit_profit_percentage" },
    { label: "Email", id: "email" },
    { label: "Full name", id: "full_name" },
    { label: "Is admin approved", id: "is_admin_approved" },
    { label: "Is day trader", id: "is_day_trader" },
    { label: "Is subscribed", id: "is_subscribed" },
    { label: "Is token generation allowed", id: "is_token_generation_allowed" },
    { label: "Is weekly email report enabled", id: "is_weekly_email_report_enabled" },
    { label: "New subscription plan", id: "new_subscription_plan" },
    { label: "Payment status", id: "payment_status" },
  ]; // List of columns for dropdown
const conditions = [ { label: "Equals", id: "equals" }, { label: "Contains", id: "Contains" }, { label: "Greater Than", id: "greater_than" }, { label: "Less Than", id: "less_than" }]; // List of conditions for dropdown
  const [selectedColumn, setSelectedColumn] = useState('');
  const [selectedCondition, setSelectedCondition] = useState('');
  const [value, setValue] = useState('');
  const [applyFilter, setApplyFilter] = useState(false);
  const [reApplyFilter, setReApplyFilter] = useState(false);

  const handleColumnSelect = (column) => {
    setSelectedColumn(column);
    // setReApplyFilter(false);
    // handleClose();
  };

  const handleConditionSelect = (condition) => {
    setSelectedCondition(condition);
    // setReApplyFilter(false);
    // handleClose();
  };

  const handleValueChange = (value) => {
    setValue(value);
    // setReApplyFilter(false);
  };

  const handleApplyFilter = () => {
    // onApplyFilter({
    //   column: selectedColumn,
    //   condition: selectedCondition,
    //   value: value,
    // });
  };

  const handleClose = () => {
    setAnchorEl(null);
    // setApplyFilter(false)
    // setReApplyFilter(false);
  };
  const handleApply = () => {
    setAnchorEl(null);
    setApplyFilter(true)
    setReApplyFilter(true);
  }
  const handleClear = () => {
    setApplyFilter(false);
    setSelectedColumn('')
    setSelectedCondition('')
    setValue('')
  }

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
            backgroundColor:"red"
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
          className="mobileLogoSection"
        >
          <LogoSection />
        </Box>
        <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background: theme?.customization?.themeColor === "dark" ?  theme.darkMode.mainBackground : theme.palette.primary.light,
              color:  theme?.customization?.themeColor === "dark" ? '#fff' : theme.palette.primary.dark,
              "&:hover": {
                background: theme.palette.primary.main,
                color: theme.palette.primary.light,
              },
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            {leftDrawerOpened && <IconMenu2 stroke={1.5} size="1.3rem" />}
            {!leftDrawerOpened && (
              <IconMenu2 stroke={1.5} size="1.3rem" />
            )}
          </Avatar>
        </ButtonBase>
      </Box>
      <Box sx={{ ml: 2, flexGrow: 0.1 }} className="browserAccountDropDownView">
        <AccountDropDownSection applyFilter={applyFilter} column={selectedColumn} condition={selectedCondition} filterValue = {value} reApplyFilter={reApplyFilter} setReApplyFilter={setReApplyFilter} />
        <CountriesList />
       
        
      </Box>
      <IconButton
        aria-controls="filter-menu"
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {applyFilter?<FilterAltIcon sx={{color:"#00BEFA"}} /> : <FilterAltOutlinedIcon sx={{color:"#00BEFA"}} /> }
        
        </IconButton>
        <HeaderFilter selectedColumn={selectedColumn} selectedCondition={selectedCondition} value={value} handleColumnSelect={handleColumnSelect} handleConditionSelect={handleConditionSelect} handleClear={handleClear} handleClose={handleClose} handleApply={handleApply} handleValueChange={handleValueChange} columns={columns} conditions={conditions} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      {/* header search */}
      {/* <SearchSection /> */}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {/* notification & profile */}
      {/* <HelpSection />
      <SettingSection />
      <NotificationSection /> */}
      <ProfileSection />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
