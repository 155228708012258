import { useEffect, useState } from "react";

import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  List,
  Typography,
  ListItemText,
  ListItemButton,
  Collapse,
} from "@mui/material";

// project imports
import NavItem from "../NavItem";
import NavCollapse from "../NavCollapse";
import { IconChevronDown, IconChevronUp } from "@tabler/icons";
import { useCookies } from "react-cookie";

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [selected, setSelected] = useState(null);
  const [cookies, setCookie] = useCookies(["login_session"]);
  const superAdmin = true;
  const supportAdmin = true;

  const currentRoles = [];
  
  const handleClick = () => {
    setOpen(!open);
    setSelected(!selected ? item.id : null);
  };
  
  if (superAdmin && supportAdmin) {
    currentRoles.push('superAdmin', 'supportAdmin');
  } else if(superAdmin) {
    currentRoles.push('superAdmin');
  } else if (supportAdmin) {
    currentRoles.push('supportAdmin');
  }

  // menu list collapse & items
  const filterMenu = item.children?.filter((menu) => {
    for(let item of currentRoles) {
      return menu.roles[item] === true
    }
  })

  
  const items = filterMenu.map((menu) => {
    switch (menu.type) {
      case "collapse":
        return <NavCollapse key={menu.id} menu={menu} level={1} />;
      case "item":
        return <NavItem key={menu.id} item={menu} level={1} />;
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <>
      <ListItemButton
        sx={{
          mb: 0.5,
          alignItems: "flex-start",
          backgroundColor: "transparent",
          color: "#CED1D6",
          paddingLeft: "6px",
          paddingRight: "6px",
          //  py: level > 1 ? 1 : 1.25,
          // pl: `${level * 24}px`
          ":hover": {
            backgroundColor: "#fff",
            background: "#fff",
            color: "#CED1D6",
            " > .MuiListItemText-root .MuiTypography-root": {
              color: "#CED1D6",
            },
          },
          ".MuiListItemText-root .MuiTypography-root": {
            marginBottom: "0px",
          },
        }}
        // selected={selected === item.id}
        onClick={handleClick}
      >
        <ListItemText
          primary={
            <Typography
              variant="caption"
              sx={{ ...theme.typography.navsubtitle }}
              display="block"
              gutterBottom
            >
              {item.title}
            </Typography>
          }
          secondary={
            item.caption && (
              <Typography
                variant="caption"
                sx={{ ...theme.typography.subMenuCaption }}
                display="block"
                gutterBottom
              >
                {item.caption}
              </Typography>
            )
          }
        />
        {open ? (
          <IconChevronUp
            stroke={1.5}
            size="1rem"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          />
        ) : (
          <IconChevronDown
            stroke={1.5}
            size="1rem"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          />
        )}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
        // sx={{
        //   position: "relative",
        //   "&:after": {
        //     content: "''",
        //     position: "absolute",
        //     left: "32px",
        //     top: 0,
        //     height: "100%",
        //     width: "1px",
        //     opacity: 1,
        //     background: theme.palette.primary.light,
        //   },
        // }}
        >
          {items}
        </List>
      </Collapse>

      {/* <Collapse in={true} timeout="auto" unmountOnExit>
      <List
        subheader={
          item.title && (
            <Typography variant="caption" sx={{ ...theme.typography.navsubtitle }} display="block" gutterBottom>
              {item.title}
              {item.caption && (
                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
      </List>
      </Collapse> */}
      {/* group divider */}
      {/* <Divider sx={{ mt: 0.25, mb: 1.25 }} /> */}
    </>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;
