import axiosApi from '../helpers/axiosApi';
import { format } from "date-fns";
import dayjs from "dayjs";
import axios from 'axios';
import config from 'config';
export const getAccounts = async (email) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/account/${email}`, 'get');
    resolve(response);
  });
}

export const getOptionsActivities = async (uuid, start_date, end_date) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/options-activity/account-id/${uuid}/start-date/${start_date}/end-date/${end_date}/`, 'get');
    if (response) {
      resolve(response);
    } else {
      resolve([])
    }
  });
}

export const getInvoice = async (email, superAdmin = false) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_OLD_API_URL}/Invoice/getInvoice?email=${superAdmin ? 'ADMIN' : email}`, 'get', '', '', '');
    resolve(response);
  });
}

export const getCommunityInsights = async () => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_OLD_API_URL}/Insights/getCommunityInsights`, 'get', '', '', '');
    resolve(response);
  });
}

export const getPortfolio = async (uuid) => {
  const currentDate = dayjs().format("YYYY-MM-DD")
  return new Promise(async (resolve, reject) => {
    //let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/portfolio/${uuid}/created-date/${currentDate}`, 'get', '', '', '');
    // to get latest date portfolio
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/portfolio/${uuid}`, 'get', '', '', '');
    resolve(response);
  });
}

export const getConsolidatedPortfolio = async () => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/backoffice/common/consolidated/portfolio/`, 'get', '', '', '');
    resolve(response);
  });
}

export const getConsolidatedOrders = async (startDate, endDate) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/backoffice/common/consolidated/orders/${(startDate && endDate) ? '?start_date=' + startDate + '&end_date=' + endDate : ''}`, 'get', '', '', '');
    resolve(response);
  });
}

export const getConsolidatedAccounts = async (startDate, endDate) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/backoffice/common/consolidated/account_details/${(startDate && endDate) ? '?start_date=' + startDate + '&end_date=' + endDate : ''}`, 'get', '', '', '');
    resolve(response);
  });
}

export const updateSubscription = async (accountUUID, formValues) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/account/${accountUUID}/update/`, 'put', formValues, "application/json");
    resolve(response);
  });
}

export const getAccountSummary = async (accountUUID, startDate, endDate) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/account-summary/account-id/${accountUUID}/start-date/${startDate}/end-date/${endDate}/`, 'get', '', '', '');
    resolve(response);
  })
}
export const getStatsDetails = async () => {
  return new Promise(async (resolve, reject) => {
    // let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/insights/getQGStats`, 'get');
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/insights/stats/`, 'get');
    resolve(response);
  });
}

export const getProfileDetails = async (profileDetailsUUID) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/profile-details/profile-id/${profileDetailsUUID}`, 'get');
    resolve(response);
  });
}

export const updateProfile = async (email, formValues) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/profile/${email}/`, 'put', formValues, "application/json");
    resolve(response);
  });
}

export const getProfile = async (email) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/profile/${email}/`, 'get');
    resolve(response);
  });
}

export const updateProfileDetails = async (profileUUID, formValues) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/profile-details/profile-id/${profileUUID}/`, 'put', formValues, "application/json");
    resolve(response);
  });
}

export const createProfileDetails = async (profileDetailsUUID, formValues) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/profile-details/`, 'post', formValues, "application/json");
    resolve(response);
  });
}



export const updateAccountSubcription = async (account_id) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/invoice/account/${account_id}`, 'put', {}, "application/json");
    resolve(response);
  });
}



export const runOndemandTradesWheel = async () => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/CORE/execute-wheel-ondemand-trades/`, 'get');
    resolve(response);
  });
}
export const getBotResponse = async (payload) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/bot/generate-response/v2/`, 'post', payload, "application/json");
    resolve(response);
  });
}
export const getSubscriptionInvoice = async (account_id, superAdmin = false) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/billing/invoice?account_id=${superAdmin ? 'ADMIN' : account_id}`, 'get', '', '', '');
    resolve(response);
  });
}
export const getClientPortalURL = async (account_id, superAdmin = false) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/stripe/get-client-portalurl?account_id=${superAdmin ? 'ADMIN' : account_id}`, 'get', '', '', '');
    resolve(response);
  });
}
export const addNewAccount = async (formValues) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_ADD_NEW_ACCOUNT_API_URL}?code=${formValues.code}&email=${formValues.email}`, 'get', '', '', '', '003eccef517542e680345c02e18da6fc');
    resolve(response);
  })
}

export const getInvoiceSubscription = async (accountUUID) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/invoice/account/${accountUUID}`, 'get');
    resolve(response);
  });
}

export const getStockScreeningData = async (accountUUID) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/stock_screener`, 'get', '', '', '');
    resolve(response);
  });
}

export const updateStockScreening = async (stock_screener_id, formValues) => {
  // return new Promise(async (resolve, reject) => {
  //   await axios({
  //     method: 'put',
  //     url: `https://qtcommonutilsbeta.azurewebsites.net/stock_screener/${stock_screener_id}/`,
  //     data: formValues,
  //     headers: {
  //       "Content-Type": header,
  //       "Ocp-Apim-Subscription-Key": subscriptionKey,
  //       credentials: true
  //     },
  //     responseType: responseType
  //   })
  //     .then(function (response) {
  //       let data = response.data;
  //       resolve(data)
  //     })
  //     .catch(function (err) {
  //       if (err.response && err.response.status === 404) {
  //                         console.log('Oops!! Session expried or Not Authorized')
  //       }else{
  //         resolve(err.response.data)
  //       }
  //       // throw err;
  //     });
  // });
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_STOCK_SCREENER_UPDTE_API}${stock_screener_id}/`, 'put', formValues, "application/json");
    resolve(response);
  });
}

export const getStockDetails = async () => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/stock_details/?include=few`, 'get', '', '', '');
    resolve(response);
  });
}

export const putStockDetails = async (stock_id, formValues)  => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/stock_details/${stock_id}/`, 'put', formValues, "application/json");
    resolve(response);
  })
}


export const postStockDetails = async (formValues)  => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/stock_selection/`, 'post', formValues, "application/json");
    resolve(response);
  })
}

export const accountStrategyAllocation = async (start_date,end_date, formValues) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/account_strategy_allocation/${start_date}&${end_date}/`, 'post', formValues, "application/json");
    resolve(response);
  });
}

export const getChatBotThreadId = async () => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/bot/generate/thread_id/`, 'get', {}, "application/json");
    resolve(response);
  });
}