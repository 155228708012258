import mainMenu from './mainMenu';
import others from './others';
import researchInsights from './research_Insights';
import superAdminMenu from './super_admin';
import supportAdminMenu from './support_admin';
import settings from './settings';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [mainMenu, researchInsights, others,settings]
 // items: [mainMenu, researchInsights, others, superAdminMenu, supportAdminMenu]
};

export default menuItems;
