import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Chatbot from "react-chatbot-kit";
import config from "./utils/Config";
import MessageParser from "./utils/MessageParser";
import ActionProvider from "./utils/ActionProvider";
import DarkBotIcon from "../../assets/images/QT_Dark_Bot.jpg"
import LightBotIcon from "../../assets/images/QT_Light_Bot.jpg"
import "react-chatbot-kit/build/main.css";
import "./index.css";
import { getChatBotThreadId } from "commonApi";

function ChatBot() {
    const [isBot, setIsBot] = useState(false);
    const themeColor = useSelector((state) => state.customization.themeColor)
    const chatRef = useRef(null);
    const btnRef = useRef(null);

    useEffect(() => {
        if (!sessionStorage.getItem('thread_id'))
            getThreadId()
    }, [])

    async function getThreadId() {
        const threadId_response = await getChatBotThreadId();
        sessionStorage.setItem('thread_id', threadId_response?.thread_id)
    }

    useEffect(() => {
        const botHeaderBackground = document.getElementsByClassName('react-chatbot-kit-chat-header')
        const botMsgBackground = document.getElementsByClassName('react-chatbot-kit-chat-message-container')
        const botInputMsgcolor = document.getElementsByClassName('react-chatbot-kit-chat-input')

        if(botHeaderBackground[0]) botHeaderBackground[0].style = `background: ${themeColor === "dark" ? '#313842' : '#fff'} !important; color: ${themeColor === 'dark' ? '#fff': '#000' }`
        if(botMsgBackground[0]) botMsgBackground[0].style = `background: ${themeColor === "dark" ? '#272E34' : '#F8F8F8'} !important; max-width: 100%;`
        if(botInputMsgcolor[0]) botInputMsgcolor[0].style = `color: ${themeColor === 'dark' ? '#fff' : '#000'} !important; background: ${themeColor === 'dark' ? '#313842' : '#fff'}; border: solid #d8d8d8 1px;`
    },[themeColor, isBot])

    useEffect(() => {
        /**
         * close the chat if clicked on outside of element
         */
        function handleClickOutside(event) {
          if (chatRef.current && btnRef.current && !chatRef.current.contains(event.target) && !btnRef.current.contains(event.target)) {
            setIsBot(false);
          }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [chatRef])

    const saveMessages = (messages, HTMLString) => {  
        sessionStorage.setItem('chat_messages', JSON.stringify(messages));
    };

    const loadMessages = () => {
        const messages = JSON.parse(sessionStorage.getItem('chat_messages'));
        return messages;
    };
    

    return (
        <div className="chat-bot-root">
            <button 
                ref={btnRef}
                onClick={() => setIsBot((prev) => !prev)} 
                className="chat-bot-trigger-btn"
            >
                <img src={themeColor === 'dark' ? DarkBotIcon : LightBotIcon} alt="Bot" />
                {/* Bot */}
            </button>
            {isBot && <div className="chabot-wraper" ref={chatRef}>
            <Chatbot
                config={config}
                messageParser={MessageParser}
                actionProvider={ActionProvider}
                messageHistory={loadMessages()}
                saveMessages={saveMessages}
                headerText="Geek"
            />
            </div>}
        </div>
    );
}

export default ChatBot;
