import { useState, useRef, useEffect, useLayoutEffect } from "react";
import { useCookies } from "react-cookie";
import PropTypes from "prop-types";
import { forwardRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, ButtonBase } from "@mui/material";

// assets
import { getAccounts } from "commonApi/index";
import SelectElement from "ui-component/Select";
import { SET_USER_ACCOUNT_LIST } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "ui-component/Loader";
// ==============================|| NOTIFICATION ||============================== //

const AccountDropdownSection = forwardRef(({ superAdmin, applyFilter, column, condition, filterValue,reApplyFilter,setReApplyFilter }, ref) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false)
  const [isportloading, setIsportloading] = useState(false)

  const accountSelectedPre = useSelector(
    (state) => state.customization.userAccountDetailsList.selectedAccount
  );

  const [accountDetailsList, setAccountDetailsList] = useState(null);
  const [accountDetails, setAccountDetails] = useState(null);
  const [cookies, setCookie] = useCookies(["login_session"]);
 
  useLayoutEffect(() => {
    
    if((cookies && cookies.login_session && cookies.login_session.email)){
      fetchData();
    }
  }, [applyFilter,reApplyFilter,cookies && cookies.login_session && (cookies.login_session.is_support_admin || cookies.login_session.is_super_admin)]);

  useEffect(() => {
    if (location) {
      fetchData();
    }
  },[location]);

  useEffect(() => {
    try{
    if (accountSelectedPre) {
      // This will run whenever accountSelectedPre changes
      console.log('Selected account changed:', accountSelectedPre);
      
      // Check if the new selected account exists in the current accountDetailsList
      if (accountDetailsList && accountDetailsList.length > 0) {
        const selectedAccountExists = accountDetailsList.some(
          account => account.account_id === accountSelectedPre.account_id
        );
  
        if (!selectedAccountExists) {
          // If the selected account doesn't exist in the current list, fetch new data
          console.log('Selected account not found in current list. Fetching new data...');
          setIsLoading(true);
          // fetchData();
        } else {
          // If the account exists, update the local state
          setAccountDetails(accountSelectedPre);
          setIsportloading(false)
         setIsLoading(false);

        }
      }
    }
  }
  catch(E){
    
  }
  }, [accountSelectedPre]);
  const isDate = (dateString) =>{
    const date = new Date(dateString);
    return !isNaN(date.getTime()) && dateString.trim() !== '';
  }
  const fetchData = async (selectedAccount) => {
    let accountList = await getAccounts("");
    if (accountList.length > 0) {
      const accountDropDown = [];
      
      accountList.forEach((element, index, array) => {
        if(applyFilter){
          if(filterValue !== ""){
            if(column !== ""){
              if(condition !== ""){
                

                
                if(condition === 'equals'){
                  if(element[column] !== null && typeof(element[column]) === 'number' && !isNaN(element[column])){
                    if(element[column] == filterValue){
                      accountDropDown.push({
                        ...element,
                        label: element.account_alias_name,
                        id: element.account_id,
                      });
                    }
                  }else if(element[column]!==null && element[column].toLowerCase() == filterValue.toLowerCase() ){
                    accountDropDown.push({
                      ...element,
                      label: element.account_alias_name,
                      id: element.account_id,
                    });
                  }
  
                }else if(condition === 'Contains'){
                  
                  if(element[column] !== null && typeof(element[column]) === 'number' && !isNaN(element[column])){
                    if(element[column].toString().includes(filterValue)){
                      accountDropDown.push({
                        ...element,
                        label: element.account_alias_name,
                        id: element.account_id,
                      });
                    }
                  }else {
                    // console.log(element[column], filterValue,"inside else>>>>>>",element[column].includes(filterValue))
                    if(element[column] !== null && element[column].toLowerCase().includes(filterValue.toLowerCase() )){
                      accountDropDown.push({
                        ...element,
                        label: element.account_alias_name,
                        id: element.account_id,
                      });
                    }
                  }
  
                }else if(condition === 'greater_than'){
                 
                  if(element[column] !== null && typeof(element[column]) !== 'number' && isDate(element[column]) && isDate(filterValue)){
                    if(new Date(element[column]) > new Date(filterValue)){
                      accountDropDown.push({
                        ...element,
                        label: element.account_alias_name,
                        id: element.account_id,
                      });
                    }

                    // console.log("its a date column.....")
                  }else{
                    if(element[column] !== null && typeof(element[column]) === 'number' && !isNaN(element[column])){
                      if(element[column] > (filterValue)){
                        accountDropDown.push({
                          ...element,
                          label: element.account_alias_name,
                          id: element.account_id,
                        });
                      }
  
                    }

                  }
                  
                 
  
                }else if(condition === 'less_than'){
                  if(element[column] !== null && typeof(element[column]) !== 'number' && isDate(element[column]) && isDate(filterValue)){
                    if(new Date(element[column]) < new Date(filterValue)){
                      accountDropDown.push({
                        ...element,
                        label: element.account_alias_name,
                        id: element.account_id,
                      });
                    }

                    // console.log("its a date column.....")
                  }else{
                    if( element[column] !== null && typeof(element[column]) === 'number' && !isNaN(element[column])){
                      if(element[column] < (filterValue)){
                        accountDropDown.push({
                          ...element,
                          label: element.account_alias_name,
                          id: element.account_id,
                        });
                      }
  
                    }

                  }
                  
                 
                }
              }else{
                accountDropDown.push({
                  ...element,
                  label: element.account_alias_name,
                  id: element.account_id,
                });
              }
            }else{
              accountDropDown.push({
                ...element,
                label: element.account_alias_name,
                id: element.account_id,
              });
            }
          }else{
            accountDropDown.push({
              ...element,
              label: element.account_alias_name,
              id: element.account_id,
            });
          }
        }else{
          accountDropDown.push({
            ...element,
            label: element.account_alias_name,
            id: element.account_id,
          });
        }
        
      });
      let accountDetailsArr = [];
      const filterAcc = accountDropDown.filter(
        (e) =>
          e.account_id ===
          (selectedAccount
            ? selectedAccount
            : accountSelectedPre && accountSelectedPre.account_id
            ? accountSelectedPre.account_id
            : accountDropDown[0].account_id)
      );
      
     if(accountDropDown.length >0){
      if(applyFilter){
        dispatch({
          type: SET_USER_ACCOUNT_LIST,
          userAccountDetailsList: {
            accountList: accountDropDown,
            selectedAccount: filterAcc[0],
          },
        });
      }else{
        dispatch({
          type: SET_USER_ACCOUNT_LIST,
          userAccountDetailsList: {
            accountList: accountDropDown,
            selectedAccount: filterAcc[0],
          },
        });
      }
     }else{
      dispatch({
        type: SET_USER_ACCOUNT_LIST,
        userAccountDetailsList: {
          accountList: accountDropDown,
          selectedAccount: null,
        },
      });
      setAccountDetails([]);
     }
      
      setAccountDetailsList(accountDropDown);
    } else {
      dispatch({
        type: SET_USER_ACCOUNT_LIST,
        userAccountDetailsList: { accountList: [], selectedAccount: null },
      });
      setAccountDetails([]);
    }
    setReApplyFilter(false)
  };

  const handleAccountChange = (event, newValue) => {
    const selectedAcount = newValue.account_id;
    let accountDetailsArr = [];

    if (accountDetailsList.length > 0) {
      setIsLoading(true)
      setIsportloading(true)
      let filter = accountDetailsList.filter(
        (e) => e.account_id === newValue.account_id
      );

      filter.sort((a,b) => a.account_alias_name-b.account_alias_name);
      accountDetailsArr = filter[0];
  
      fetchData(selectedAcount);
     
    }
    console.log('toggle change',accountDetailsArr)
    setAccountDetails(accountDetailsArr);
  };

  const accountDropDown = (
    <>
    {isLoading && <Loader />}
    <SelectElement
      className={"primaryAccountDropdown"}
      itemList={accountDetailsList ? accountDetailsList : []}
      label={"Account"}
      handleChange={handleAccountChange}
      // getOptionDisabled={true}
      disabled={isportloading}
      defaultValue={accountDetails}
      width={"100%"}
    />
    </>
  );

  return accountDropDown;
});

AccountDropdownSection.propTypes = {
  superAdmin: PropTypes.bool,
};

export default AccountDropdownSection;
