/* Notes: 
      Expect id others can be changed 
      */

const OptionActivitiesHeaders = [
  {
    id: "date", //should not change
    numeric: false,
    disablePadding: false,
    label: "Date",
    align: "left",
  },
  {
    id: "ticker", //should not change
    numeric: false,
    disablePadding: false,
    label: "Ticker",
    align: "center",
  },
  {
    id: "underlying_symbol", //should not change
    numeric: false,
    disablePadding: false,
    label: "Underlying Symbol",
    align: "center",
  },
  {
    id: "order_id", //should not change
    numeric: false,
    disablePadding: false,
    label: "Order ID",
    align: "center",
  },
  {
    id: "expiry", //should not change
    numeric: false,
    disablePadding: false,
    label: "Expiry",
    align: "center",
  },
  {
    id: "transaction_type", //should not change
    numeric: false,
    disablePadding: false,
    label: "Transaction type",
    align: "center",
  },
  {
    id: "status", //should not change
    numeric: false,
    disablePadding: false,
    label: "Status",
    align: "center",
  },
  {
    id: "qty", //should not change
    numeric: false,
    disablePadding: false,
    label: "Quantity",
    align: "center",
  },
  {
    id: "status_description", //should not change
    numeric: false,
    disablePadding: false,
    label: "Description",
    align: "center",
  },
];

const PortfolioHeaders = [
  {
    id: "ticker_symbol", //should not change
    numeric: false,
    disablePadding: false,
    label: "Symbol",
    align: "left",
  },
  {
    id: "underlying_symbol", //should not change
    numeric: false,
    disablePadding: false,
    label: "Underlying Symbol",
    align: "center",
  },
  {
    id: "qty", //should not change
    numeric: false,
    disablePadding: false,
    label: "Quantity",
    align: "center",
  },
  {
    id: "average_price", //should not change
    numeric: false,
    disablePadding: false,
    label: "Trade Price",
    align: "center",
  },
  {
    id: "current_market_price", //should not change
    numeric: false,
    disablePadding: false,
    label: "Current Market Price",
    align: "center",
  },
  // {
  //   id: "market_value", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Market Value",
  //   align: "right",
  // },
  // {
  //   id: "position_status", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Position Status",
  //   align: "center",
  // },
  {
    id: "put_call", //should not change
    numeric: false,
    disablePadding: false,
    label: "Put/Call",
    align: "center",
  },
];

const ConsolidatedPortfolioHeaders = [
  {
    id: "ticker_symbol", //should not change
    numeric: false,
    disablePadding: false,
    label: "Symbol",
    align: "left",
  },
  {
    id: "account_alias_name", //should not change
    numeric: false,
    disablePadding: false,
    label: "Account Name",
    align: "center",
  },
  {
    id: "qty", //should not change
    numeric: false,
    disablePadding: false,
    label: "Qty",
    align: "center",
  },
  {
    id: "average_price", //should not change
    numeric: false,
    disablePadding: false,
    label: "Avg Price",
    align: "right",
  },
  {
    id: "current_market_price", //should not change
    numeric: false,
    disablePadding: false,
    label: "Market Price",
    align: "right",
  },
  // {
  //   id: "strategy", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Strategy",
  //   align: "center",
  // },
  // {
  //   id: "mode_of_management", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Mode of Mgmt",
  //   align: "center",
  // },
  
   {
    id: "decision_equity_stock", //should not change
    numeric: false,
    disablePadding: false,
    label: "Stock [Decision]",
    align: "center",
  },
  {
    id: "decision_longcall", //should not change
    numeric: false,
    disablePadding: false,
    label: "Long [Decision]",
    align: "center",
  },
  {
    id: "decision_wheel", //should not change
    numeric: false,
    disablePadding: false,
    label: "Wheel [Decision]",
    align: "center",
  },
  {
    id: "put_call", //should not change
    numeric: false,
    disablePadding: false,
    label: "Type",
    align: "center",
  },
  // {
  //   id: "decision", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Decision",
  //   align: "center",
  // },
  // {
  //   id: "tier", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Tier",
  //   align: "center",
  // },
];

const ConsolidatedOrdersHeaders = [
  {
    id: "account_alias_name", //should not change
    numeric: false,
    disablePadding: false,
    label: "Account Alias Name",
    align: "left",
  },
  {
    id: "order_id", //should not change
    numeric: false,
    disablePadding: false,
    label: "Order ID",
    align: "center",
  },
  {
    id: "order_date", //should not change
    numeric: false,
    disablePadding: false,
    label: "Order Date",
    align: "center",
  },
  {
    id: "ticker_symbol", //should not change
    numeric: false,
    disablePadding: false,
    label: "Symbol",
    align: "center",
  },
  {
    id: "qty", //should not change
    numeric: false,
    disablePadding: false,
    label: "Qty",
    align: "center",
  },
  {
    id: "status", //should not change
    numeric: false,
    disablePadding: false,
    label: "Status",
    align: "center",
  },
  {
    id: "net_credit", //should not change
    numeric: false,
    disablePadding: false,
    label: "Net Credit",
    align: "center",
  },
];

const ConsolidatedAccountsHeaders = [
  {
    id: "account_alias_name", //should not change
    numeric: false,
    disablePadding: false,
    label: "Account Name",
    align: "left",
  },
  {
    id: "previous_balance", //should not change
    numeric: false,
    disablePadding: false,
    label: "Previous Balance",
    align: "right",
  },
  {
    id: "new_balance", //should not change
    numeric: false,
    disablePadding: false,
    label: "New Balance",
    align: "right",
  },
  {
    id: "return_percentage", //should not change
    numeric: false,
    disablePadding: false,
    label: "PnL %",
    align: "center",
  },
  {
    id: "pnl_amount", //should not change
    numeric: false,
    disablePadding: false,
    label: "PnL",
    align: "center",
  },{
  id: "unallocated_balance", //should not change
  numeric: false,
  disablePadding: false,
  label: "Unallocated Balance",
  align: "center",
},
  {
    id: "recent_trade_date", //should not change
    numeric: false,
    disablePadding: false,
    label: "Recent Trade Date",
    align: "center",
  },
  {
    id: "is_account_healthy", //should not change
    numeric: false,
    disablePadding: false,
    label: "Status",
    align: "center",
  },
  {
    id: "action", //should not change
    numeric: false,
    disablePadding: false,
    label: "Action",
    align: "center",
  },
];

const CommunityInsightsHeaders = [
  {
    id: "ticker", //should not change
    numeric: false,
    disablePadding: false,
    label: "Symbol",
    align: "left",
  },
  {
    id: "currentMarketPrice", //should not change
    numeric: false,
    disablePadding: false,
    label: "Market Price",
    align: "center",
  },
  {
    id: "averagePremium", //should not change
    numeric: false,
    disablePadding: false,
    label: "Avg. Premium",
    align: "center",
  },
  {
    id: "averagePremiumPercentage", //should not change
    numeric: false,
    disablePadding: false,
    label: "Avg Premium %",
    align: "center",
  },
  {
    id: "total_csp_contracts", //should not change
    numeric: false,
    disablePadding: false,
    label: "Total Contracts Placed[CSP]",
    align: "center",
  },
  {
    id: "noOfTimesEarlyProfitBooked", //should not change
    numeric: false,
    disablePadding: false,
    label: "No Early Profit Booking[CSP]",
    align: "center",
  },
  {
    id: "total_cc_contracts", //should not change
    numeric: false,
    disablePadding: false,
    label: "Total Contracts Placed[CC]",
    align: "center",
  },
  {
    id: "num_times_cc_profit_exit", //should not change
    numeric: false,
    disablePadding: false,
    label: "No Early Profit Booking[CC]",
    align: "center",
  },
  // {
  //   id: "noOfTimesCCExited", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   label: "No of times StopLoss CC",
  //   align: "right",
  // },
  // {
  //   id: "noOfTimesCSPExited", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   label: "No of times StopLoss CSP",
  //   align: "right",
  // },
];

const invoiceHeaders = [
  {
    id: "invoiceNumber", //should not change
    numeric: false,
    disablePadding: false,
    label: "Invoice Number",
    align: "left",
  },
  {
    id: "tdaAccountAlias", //should not change
    numeric: false,
    disablePadding: false,
    label: "Account Alias Name",
    align: "left",
  },
  {
    id: "invoicePeriod", //should not change
    numeric: false,
    disablePadding: false,
    label: "Invoice Period",
    align: "center",
  },
  {
    id: "invoiceAmount", //should not change
    numeric: false,
    disablePadding: false,
    label: "Invoice Amount",
    align: "right",
  },
  {
    id: "subscriptionPlanName", //should not change
    numeric: false,
    disablePadding: false,
    label: "Plan Name",
    align: "center",
  },
  {
    id: "invoicePaidStatus", //should not change
    numeric: false,
    disablePadding: false,
    label: "invoice Status",
    align: "center",
  },
];

const billingInvoiceHeaders = [
  {
    id: "invoiceNumber", //should not change
    numeric: false,
    disablePadding: false,
    label: "Invoice Number",
    align: "left",
  },
  {
    id: "invoiceDate", //should not change
    numeric: false,
    disablePadding: false,
    label: "Invoice Date",
    align: "center",
  },
  {
    id: "invoiceAmount", //should not change
    numeric: false,
    disablePadding: false,
    label: "Invoice Amount",
    align: "center",
  },
  {
    id: "invoiceLink", //should not change
    numeric: false,
    disablePadding: false,
    label: "View Invoice",
    align: "center",
  },
];

const dashboardPortfolioCardHeadCells = [
  {
    id: "ticker_symbol",
    numeric: false,
    disablePadding: false,
    label: "Symbol",
    align: "left",
    //  with: "40px",
  },
  {
    id: "qty",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
    align: "center",
    with: "40px",
  },
  {
    id: "average_price",
    numeric: false,
    disablePadding: false,
    label: "Trade Price",
    align: "center",
  },
  {
    id: "current_market_price",
    numeric: false,
    disablePadding: false,
    label: "Market Price",
    align: "center",
  },
];

const dashboardActivityCardHeadCells = [
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
    align: "left",
  },
  {
    id: "ticker_symbol",
    numeric: false,
    disablePadding: false,
    label: "Symbol",
    align: "center",
  },
  {
    id: "underlying_symbol",
    numeric: false,
    disablePadding: false,
    label: "Underying Symbol",
    align: "center",
  },
  {
    id: "expiry",
    numeric: false,
    disablePadding: false,
    label: "Expiry",
    align: "center",
  },
  {
    id: "order_id",
    numeric: false,
    disablePadding: false,
    label: "Order ID",
    align: "center",
  },
  {
    id: "qty",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
    align: "center",
  },
];
const StockScreenerHeaders = [
  {
    id: "ticker", //should not change
    numeric: false,
    disablePadding: false,
    label: "Symbol",
    align: "left",
  },
  {
    id: "is_ta_approved", //should not change
    numeric: false,
    disablePadding: false,
    label: "TA approved",
    align: "center",
  },
  {
    id: "is_admin_approved", //should not change
    numeric: false,
    disablePadding: false,
    label: "Admin approved",
    align: "center",
  },
  {
    id: "priority", //should not change
    numeric: false,
    disablePadding: false,
    label: "Priority",
    align: "center",
  },
  {
    id: "market_price", //should not change
    numeric: false,
    disablePadding: false,
    label: "Market Price",
    align: "center",
  },
  {
    id: "upcoming_earnings_date", //should not change
    numeric: false,
    disablePadding: false,
    label: "Upcoming Earning",
    align: "center",
  },
  {
    id: "sma_10_trend", //should not change
    numeric: false,
    disablePadding: false,
    label: "Sma 10 trend",
    align: "center",
  },
  {
    id: "sma_20_trend", //should not change
    numeric: false,
    disablePadding: false,
    label: "Sma 20 trend",
    align: "center",
  },
  {
    id: "sma_50_trend", //should not change
    numeric: false,
    disablePadding: false,
    label: "Sma 50 trend",
    align: "center",
  },
  {
    id: "sma_200_trend", //should not change
    numeric: false,
    disablePadding: false,
    label: "Sma 200 trend",
    align: "center",
  },

  {
    id: "is_earnings_within_2w", //should not change
    numeric: false,
    disablePadding: false,
    label: "Is earnings within 2w",
    align: "center",
  },
  // {
  //   id: "upcoming_earnings_date", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Upcoming earnings date",
  //   align: "right",
  // },
  {
    id: "put_l1_mid_percent", //should not change
    numeric: false,
    disablePadding: false,
    label: "Put l1 mid percent",
    align: "center",
  },
  {
    id: "put_l2_mid_percent", //should not change
    numeric: false,
    disablePadding: false,
    label: "Put l2 mid percent",
    align: "center",
  },
  {
    id: "put_l3_mid_percent", //should not change
    numeric: false,
    disablePadding: false,
    label: "Put l3 mid percent",
    align: "center",
  },
  {
    id: "put_l4_mid_percent", //should not change
    numeric: false,
    disablePadding: false,
    label: "Put l4 mid percent",
    align: "center",
  },
  {
    id: "put_l5_mid_percent", //should not change
    numeric: false,
    disablePadding: false,
    label: "Put l5 mid percent",
    align: "center",
  },
  {
    id: "call_l1_mid_percent", //should not change
    numeric: false,
    disablePadding: false,
    label: "Call l1 mid percent",
    align: "center",
  },
  {
    id: "call_l2_mid_percent", //should not change
    numeric: false,
    disablePadding: false,
    label: "Call l2 mid percent",
    align: "center",
  },
  {
    id: "call_l3_mid_percent", //should not change
    numeric: false,
    disablePadding: false,
    label: "Call l3 mid percent",
    align: "center",
  },
  {
    id: "call_l4_mid_percent", //should not change
    numeric: false,
    disablePadding: false,
    label: "Call l4 mid percent",
    align: "center",
  },
  {
    id: "call_l5_mid_percent", //should not change
    numeric: false,
    disablePadding: false,
    label: "Call l5 mid percent",
    align: "center",
  },
];

const AccountManagementHeaders = [
  {
    id: "account_alias_namee", //should not change
    numeric: false,
    disablePadding: false,
    label: "Account Alias Name",
    align: "left",
  },
  {
    id: "mode_of_management_notrequired", //should not change
    numeric: false,
    disablePadding: false,
    label: "Mode Of Mgmt",
    align: "center",
  },
  {
    id: "is_admin_approved_notrequired", //should not change
    numeric: false,
    disablePadding: false,
    label: "Is Admin Approved",
    align: "center",
  },
  {
    id: "is_subscribed_notrequired", //should not change
    numeric: false,
    disablePadding: false,
    label: "Is Subscribed",
    align: "center",
  },
  {
    id: "email_notrequired", //should not change
    numeric: false,
    disablePadding: false,
    label: "Profile Email",
    align: "center",
  },
  {
    id: "date_of_joining", //should not change
    numeric: false,
    disablePadding: false,
    label: "Date of Joining",
    align: "center",
  },
  {
    id: "action", //should not change
    numeric: false,
    disablePadding: false,
    label: "Action",
    align: "center",
  },
];

const qtSelectionHeaders = [
  {
    label: "Symbol", //should not change
    numeric: false,
    disablePadding: false,
    id: "ticker_notrequired",
    align: "left",
  },
  {
    label: "Market Price", //should not change
    numeric: false,
    disablePadding: false,
    id: "price",
    align: "center",
  },
  // {
  //   label: "Tier", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   id: "tier",
  //   align: "center",
  // },
  // {
  //   label: "Decision", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   id: "decision_notrequired",
  //   align: "center",
  // },
  // {
  //   label: "Shortterm Trend", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   id : 'shortterm_trend',
  //   align: "center",
  // },
  // {
  //   label: "Longterm Trend", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   id: "longterm_trend",
  //   align: "center",
  // },
  // {
  //   label: "Net premium %", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   id: "moderate_put_percentage_notrequired",
  //   align: "center",
  // },
  {
    label: "Stock [Decision]", //should not change
    numeric: false,
    disablePadding: false,
    id: "decision_equity_stock",
    align: "center",
  },
  {
    label: "Long [Decision]", //should not change
    numeric: false,
    disablePadding: false,
    id: "decision_longcall",
    align: "center",
  },  

  {
    label: "Wheel [Decision]", //should not change
    numeric: false,
    disablePadding: false,
    id: "decision_wheel",
    align: "center",
  },
  

  // {
  //   label: "Rsi", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   id : 'rsi_value',
  //   align: "center",
  // },
  // {
  //   label: "Expiry", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   id: "exp_date_notrequired",
  //   align: "center",
  // },
  {
    label: "Earnings", //should not change
    numeric: false,
    disablePadding: false,
    id: "upcoming_earnings",
    align: "center",
  },
  // {
  //   label: "TA Approved", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   id : 'is_ta_approved',
  //   align: "center",
  // },

  {
    label: "QT Favorite", //should not change
    numeric: false,
    disablePadding: false,
    id: "is_qt_favorite_notrequired",
    align: "center",
  },
  {
    label: "Pre-Authorized", //should not change
    numeric: false,
    disablePadding: false,
    id: "is_qt_preauth_enabled_notrequired",
    align: "center",
  },
  // {
  //   label: "Wheel Strategy", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   id : 'is_strategy_wheel_enabled_notrequired',
  //   align: "center",
  // },
  // {
  //   label: "Strategy put spreads", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   id : 'is_strategy_credit_put_spreads_enabled_notrequired',
  //   align: "center",
  // },
  // {
  //   label: "Strategy Call spreads", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   id : 'is_strategy_debit_call_spreads_enabled_notrequired',
  //   align: "center",
  // },
  // {
  //   label: "Strategy Long Call", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   id : 'is_strategy_long_call_enabled_notrequired',
  //   align: "center",
  // },
  // {
  //   label: "Strategy Long Put", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   id : 'is_strategy_long_put_enabled_required',
  //   align: "center",
  // },
  {
    label: "Action",
    numeric: false,
    disablePadding: false,
    id: "action",
    align: "center",
  },
];

const StrategyBasedAllocationHeaders = [
  {
    id: "account_alias_namee", //should not change
    numeric: false,
    disablePadding: false,
    label: "Account Alias Name",
    align: "left",
  },
  {
    id: "account_balance", //should not change
    numeric: false,
    disablePadding: false,
    label: "Account Balance",
    align: "center",
  },

  {
    id: "is_subscribed", //should not change
    numeric: false,
    disablePadding: false,
    label: "Is Subscribed",
    align: "center",
  },

  {
    id: "is_strategy_wheel_enabled", //should not change
    numeric: false,
    disablePadding: false,
    label: "Wheel",
    align: "center",
  },
  {
    id: "is_strategy_short_call_credit_weekly_enabled", //should not change
    numeric: false,
    disablePadding: false,
    label: "Weekly Spreads",
    align: "center",
  },
  {
    id: "is_strategy_short_put_zerodte_enabled", //should not change
    numeric: false,
    disablePadding: false,
    label: "Zero DTE Spreads",
    align: "center",
  },

  {
    id: "is_strategy_short_call_spreads_enabled", //should not change
    numeric: false,
    disablePadding: false,
    label: "Long Call & Put",
    align: "center",
  },
  {
    id: "action", //should not change
    numeric: false,
    disablePadding: false,
    label: "Action",
    align: "center",
  },
];

export {
  OptionActivitiesHeaders,
  PortfolioHeaders,
  ConsolidatedPortfolioHeaders,
  ConsolidatedOrdersHeaders,
  ConsolidatedAccountsHeaders,
  CommunityInsightsHeaders,
  invoiceHeaders,
  billingInvoiceHeaders,
  dashboardPortfolioCardHeadCells,
  dashboardActivityCardHeadCells,
  StockScreenerHeaders,
  AccountManagementHeaders,
  qtSelectionHeaders,
  StrategyBasedAllocationHeaders,
};
