//import PropTypes from 'prop-types';
import { forwardRef } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";

// ==============================|| CUSTOM Table Action Section ||============================== //

const EmptyCard = forwardRef(({ actionItems }, ref) => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        color: theme?.customization?.themeColor === "dark" ? "#fff" : "#5c6e9a",
        padding: "15px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        fontSize: '25px',
        fontWeight: 700,
        lineHeight: '26px',
        textAlign: 'center',
      }}
    >
        There are no account(s) linked with your profile
        <br/>
        <br/>
        <span>If your brokerage is TDAmeritrade/E-Trade, Go to  <Link to={'/add-link-account'} style={{display: 'inline'}}> Add/Link Account</Link> Page & link directly from platform. For more details on onboarding</span>
        <a href="https://quantumtrades.com/quantumtrades-onboarding/" target="_blank"  rel="noreferrer"><Button variant="contained" size="large" sx={{mt:1}}>Click Here</Button></a>
        <br/>
        <br/>
        <span> Contact - Support@QuantumTrades.com</span>
    </Stack>
  );
});

EmptyCard.propTypes = {};

export default EmptyCard;
