// material-ui
import { styled } from "@mui/material/styles";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project imports
import AnimateButton from "ui-component/extended/AnimateButton";
import Silver from "assets/images/icons/Silver.svg";
import Gold from "assets/images/icons/Gold.svg";
import Platinum from "assets/images/icons/Platinum.svg";
import Institution from "assets/images/icons/Elite.svg";
import { useDispatch, useSelector } from "react-redux";
import * as Constants from "constants/Subscription";
import { Link } from "react-router-dom";

// styles
const CardStyle = styled(Card)(({ theme }) => ({
  // background:
  //   "linear-gradient(90deg, #FFC837 -0.67%, #FF8008 100.33%), #1875F0",
  marginBottom: "16px",
  overflow: "hidden",
  position: "relative",
  padding: "0px 10px",
  "&:after": {
    content: '""',
    position: "absolute",
    width: "200px",
    height: "200px",
    border: "19px solid ",
    borderColor: "#fff",
    borderRadius: "50%",
    top: "65px",
    right: "-150px",
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: "200px",
    height: "200px",
    border: "3px solid ",
    borderColor: "#fff",
    borderRadius: "50%",
    top: "145px",
    right: "-70px",
  },
}));

// ==============================|| PROFILE MENU - UPGRADE PLAN CARD ||============================== //

const UpgradePlanCard = ({handleClose}) => {
  const theme = useTheme();

  const accountDetails = useSelector(
    (state) => state.customization.userAccountDetailsList.selectedAccount
  );

  return (
    <CardStyle
      sx={{
        background:
          theme.palette.subscriptionBackground[
            accountDetails && accountDetails.subscription_plan_name ? 
              accountDetails.subscription_plan_name.toLowerCase().includes('gold') ? 'Gold' : 
              accountDetails.subscription_plan_name.toLowerCase().includes('platinum') ? 'Platinum' : 'Silver' : 'Silver'
          ],
      }}
    >
      <CardContent>
        {accountDetails ? (
          accountDetails.subscription_plan_name && (<Stack
            direction="row"
            spacing={3}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography variant="membershipPlanNameText" sx={{color: theme.typography.h2, textTransform: 'capitalize'}}>
              {accountDetails && accountDetails.subscription_plan_name}
            </Typography>
            {accountDetails.subscription_plan_name.toLowerCase().includes('silver') ? (
              <img src={Silver} alt="Quantum Trades" width="auto" />
            ) : accountDetails.subscription_plan_name.toLowerCase().includes('gold') ? (
              <img src={Gold} alt="Quantum Trades" width="auto" />
            ) : accountDetails.subscription_plan_name.toLowerCase().includes('platinum') ? (
              <img src={Platinum} alt="Quantum Trades" width="auto" />
            ) : (
              <img src={Institution} alt="Quantum Trades" width="auto" />
            )}
          </Stack>)
        ) : (
          <Stack
            direction="row"
            spacing={3}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography variant="">
              Your not subscribed to any plan,<br /> click Upgrade button to subscribed
            </Typography>
          </Stack>
        )}

        <Stack direction="row" justifyContent="flex-start">
          <Typography variant="membershipPlanTitleText" sx={{color: theme.typography.body1}}>
          {accountDetails.subscription_plan_name !== null ? 'Membership plan' : <>There is no membership plan <br/> currently subscribed.</>}
          </Typography>
        </Stack>
        <Stack direction="row">
          <AnimateButton sx={{background: theme.Button}}>
            <Link
              to="/billing#1"
              style={{ textDecoration: "none !important" }}
              onClick={handleClose}
            >
              <Button
                variant="contained"
                color="warning"
                sx={{
                  boxShadow: "none",
                  mt: 2,
                  fontSize: "14px",
                  fontWeight: 700,
                  background: "#fff",
                  color:
                    theme.palette.subscriptionColors[
                      accountDetails && accountDetails.subscription_plan_name
                    ],
                  "&:hover": {
                    fontSize: "14px",
                    fontWeight: 700,
                    background: "#fff",
                    color:
                      theme.palette.subscriptionColors[
                        accountDetails && accountDetails.subscription_plan_name
                      ],
                  },
                }}
              >
               { accountDetails.subscription_plan_name !== null ? 'Upgrade' : 'Subscribe'}
              </Button>
            </Link>
          </AnimateButton>
        </Stack>
      </CardContent>
    </CardStyle>
  );
};

export default UpgradePlanCard;
