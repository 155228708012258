/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */

export default function themeTypography(theme) {
  return {
    fontFamily: theme?.customization?.fontFamily,
    // color: theme?.customization?.themeColor,
    h6: {
      fontWeight: 500,
      fontSize: '0.75rem',
      color: theme?.customization?.themeColor === "dark" ? '#fff' : theme.heading
    },
    h5: {
      fontSize: '0.875rem',
      color: theme?.customization?.themeColor === "dark" ? '#fff' : theme.heading,
      fontWeight: 500,
      // color: theme?.customization?.themeColor === "dark" && '#fff'
    },
    h4: {
      fontSize: '1rem',
      color: theme?.customization?.themeColor === "dark" ? '#fff' : theme.heading,
      fontWeight: 600,
       color: theme?.customization?.themeColor === "dark" && '#fff'
    },
    h3: {
      fontSize: '1.25rem',
      color: theme?.customization?.themeColor === "dark" ? '#fff' : theme.heading,
      fontWeight: 600,
      // color: theme?.customization?.themeColor === "dark" && '#fff'
    },
    h2: {
      fontSize: '1.5rem',
      color: theme?.customization?.themeColor === "dark" ? '#fff' : theme.heading,
      fontWeight: 700,
      // color: theme?.customization?.themeColor === "dark" && '#fff'
    },
    h1: {
      fontSize: '2.125rem',
      color: theme?.customization?.themeColor === "dark" ? '#fff' : theme.heading,
      fontWeight: 700,
      // color: theme?.customization?.themeColor === "dark" && '#fff'
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: 500,
      color: theme.textDark
    },
    subtitle2: {
      fontSize: '0.75rem',
      fontWeight: 400,
      color: theme.darkTextSecondary
    },
    navsubtitle: {
      fontSize: '0.75rem',
      color: theme.menuGroupName,
      fontWeight: 700,
      letterSpacing: '1px'
    },
    caption: {
      fontSize: '0.75rem',
      color: theme.darkTextSecondary,
      fontWeight: 400,
      textTransform: 'upperCase'
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '1.334em',
      color: theme?.customization?.themeColor === "dark" && '#fff'
    },
    body2: {
      letterSpacing: '0em',
      fontWeight: 400,
      lineHeight: '1.5em',
      color: theme.darkTextPrimary
    },
    button: {
      textTransform: 'capitalize'
    },
    customInput: {
      marginTop: 1,
      marginBottom: 1,
      '& > label': {
        top: 23,
        left: 0,
        color: theme.grey500,
        '&[data-shrink="false"]': {
          top: 5
        }
      },
      '& > div > input': {
        padding: '30.5px 14px 11.5px !important'
      },
      '& legend': {
        display: 'none'
      },
      '& fieldset': {
        top: 0
      }
    },
    mainContent: {
      backgroundColor: theme?.customization?.themeColor === "dark" ? theme?.darkMode.mainBackground :  theme.background,
      width: '100%',
      minHeight: 'calc(100vh - 88px)',
      flexGrow: 1,
      padding: '15px 18px 18px 18px',
      marginTop: '90px',
      marginRight: '20px',
      borderRadius: `${theme?.customization?.borderRadius}px`
    },
    menuCaption: {
      fontSize: '0.875rem',
      fontWeight: 500,
      color: theme.heading,
      padding: '6px',
      textTransform: 'capitalize',
      marginTop: '10px'
    },
    subMenuCaption: {
      fontSize: '0.6875rem',
      fontWeight: 500,
      color: theme.darkTextSecondary,
      textTransform: 'capitalize'
    },
    commonAvatar: {
      cursor: 'pointer',
      borderRadius: '8px'
    },
    smallAvatar: {
      width: '22px',
      height: '22px',
      fontSize: '1rem'
    },
    mediumAvatar: {
      width: '34px',
      height: '34px',
      fontSize: '1.2rem'
    },
    largeAvatar: {
      width: '44px',
      height: '44px',
      fontSize: '1.5rem'
    },
    subCard: {
      fontSize: '24px',
      color: theme.darkHeaderText,
      fontWeight: 700
    },
    mainContainerHeader: {
      padding: '22px 29px 5px 29px',
      '.portfolioAccountSection': {
        background: theme?.customization?.themeColor === "dark" ? '#5C687A' : theme.lightBlueBackground,
        '.subscribedSwitch': {
          '.MuiTypography-root': {
            fontSize: { lg: "20px", md: "18px", xs: "16px" },
            color: theme?.customization?.themeColor === "dark" ? '#fff' : theme.darkHeaderText,
            fontWeight: 600
          }
        }
      }
    },
    carouselTite1: {
      width: '507px',
      // height: '150px',
      fontSize: '65px',
      fontWeight: 700,
      letterSpacing: 6,
      lineHeight: '88.52px',
      Height: "267px",
      textAlign: "left",

    },
    carouselSlide2Tite1: {
      width: '300px',
      // height: '150px',
      fontSize: '48px',
      fontWeight: 600,
      letterSpacing: 4,
      lineHeight: '65.37px',
      Height: "260px",
      textAlign: "left",
      color: "#016BA7",

      // padding:10,
      '& .percentText': {
        fontSize: 105,
        fontWeight: 600,
        letterSpacing: "0.02em",
        lineHeight: "90px",
        textAlign: "left",
        color: "#016BA7"
      },
    },
    carouselSlide3Tite1: {
      width: '500px',
      // height: '150px',
      fontSize: '48px',
      fontWeight: 600,
      letterSpacing: 4,
      lineHeight: '65.37px',
      Height: "260px",
      textAlign: "left",
      color: "#00BEFA",

      // padding:10,
      '& .slide3Text2': {
        color: "#006CA7"
      }

    },
    carouselSubTite1: {
      fontSize: 40,
      fontWeight: 400,
      letterSpacing: 2,
      lineHeight: '54.47px',
      width: "315.20465087890625px",
      height: "162px",
      marginBottom: 10,
      left: '60%'

    },
    formError: {
      fontSize: 12,
    },
    formLabel: {
      color: "#5C6E9A",
      fontWeight: 700,
      fontSize: 16,
      letterSpacing: "0.3px",
      lineHeight: "28.5px",
    },
    fotgotPasswordLabel: {
      color: "#5C6E9A",
      fontWeight: 600,
      fontSize: 15,
      letterSpacing: "0.3px",
      lineHeight: "20.46px",
      textDecoration: "none",
    },
    signinText: {
      color: theme.colors?.darkHeaderText,
      fontWeight: 600,
      fontSize: 16,
      letterSpacing: "0.3px",
      lineHeight: 'normal',
      textDecoration: "none",
    },
    errorText: {
      color: '#FF6765',
      textDecoration: "none",
    },
    loginButton: {
      borderRadius: '10px',
      background: 'linear-gradient(258deg, #47E2FF 8.82%, #03B5F8 91.18%)',
      boxShadow: '0px 12px 35px 0px rgba(71, 226, 255, 0.37)',
      flexShrink: 0,
      color: '#FFF',
      height: 41,
      width: '100%',
      alignSelf: "center",
      fontSize: 18,
      '&:hover': {
        boxShadow: '0px 12px 35px 0px rgba(71, 226, 255, 0.37)',
      },
      '& .loginButtonText': {
        fontWeight: 700,
      }
    },
    loginPanel: {
      width: '100%',
      maxWidth: window.innerWidth >= 2000 ? 950 : window.innerWidth >= 1600 ? 1000 : 1280,
      borderRadius: 16,
      boxShadow: '0px 3px 17px 2px rgba(0, 0, 0, 0.25)',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -54%)',
      marginTop: window.innerWidth >= 2000 ? '-1%' : window.innerWidth >= 1600 ? '-1%' : 0
    },
    loginForm: {
      width: '90%',
      margin: '0 auto'
    },
    forgotPasswordText: {
      color: theme.colors?.grey600,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      letterSpacing: '0.3px',
      textTransform: 'capitalize'
    },
    link: {
      color: theme.colors?.primaryMain,
      fontSize: 'inherit',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      letterSpacing: '0.3px',
      textDecoration: 'underline',
      textTransform: 'capitalize'
    },
    copyrightText: {
      color: theme?.customization?.themeColor === "dark" ? '#fff' : theme.colors?.grey600,
      textAlign: 'center',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      letterSpacing: '0.875px',
      '& .copyrightLink': {
        color: theme.colors?.grey600,
        textAlign: 'center',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        letterSpacing: '0.875px',
        textDecoration: 'underline'
      }
    },
    profileStepperActiveText: {
      color: theme.colors?.primaryMain,
      // fontSize: '16px',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      letterSpacing: '0.32px',
    },
    profileStepperInactiveText: {
      color: '#54C1FC',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      letterSpacing: '0.24px',
    },
    redHighLightedText: {
      color: '#F32424',
      fontWeight: 700,
      letterSpacing: '0.24px'
    },
    greenHighLightedText: {
      color: '#2CA10F',
      fontWeight: 700,
      letterSpacing: '0.24px'
    },
    textFieldLabel: {
      color: '#5C6E9A',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      letterSpacing: '0.3px',
      textTransform: 'capitalize',
      marginBottom: '6px',
    },
    stepperInfoText: {
      color: '#5C6E9A',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '20px', 
      letterSpacing: '0.3px',
    },
    radioTableLabelText: {
      color: '#5C6E9A',
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.3px',
    },
    radioTableHeaderText: {
      color: '#5C6E9A',
      fontSize: '14px',
      fontWeight: 700,
      letterSpacing: '0.3px',
    },
    statsTitleText: {
      color: theme?.customization?.themeColor === "dark" ? '#fff' : '#0C1E5B',
      fontSize: '16px',
      fontWeight: 600,
      letterSpacing: '0.28px',
    },
    statsNumericText: {
      color: '#00BEFA',
      fontSize: '32px',
      fontWeight: 400,
      textAlign: 'right',
      letterSpacing: '0.64px',
    },
    statsPriceText: {
      color: '#41BE21',
      fontSize: '32px',
      fontWeight: 400,
      textAlign: 'right',
      letterSpacing: '0.64px',
    }
  };
}
