//Membership Logos
import Gold from "assets/images/icons/Gold_colored.svg";
import Silver from "assets/images/icons/Silver_colored.svg";
import Institution from "assets/images/icons/Elite_colored.svg";
import Platinum from "assets/images/icons/Platinum_colored.svg";

import Gold_ from "assets/images/icons/Gold.svg";
import Silver_ from "assets/images/icons/Silver.svg";
import Institution_ from "assets/images/icons/Elite.svg";
import Platinum_ from "assets/images/icons/Platinum.svg";

const subscriptionPlans = [
    {
      title: "Silver",
      logo: Silver,
      whiteLogo: Silver_,
      monthlyAmt: 49,
      annualAmt: 499,
      currencySymbol: "$",
      chipForMonthly: "Student Free !",
      chipForAnnual: "Student Free !",
      noOfOptionTrades: "20",
      discountValue: "16.67% discount",
      features: [{ main: "Options Platform" }, { main: "Market Insights" }],
    },
    {
      title: "Gold",
      logo: Gold,
      whiteLogo: Gold_,
      monthlyAmt: 99,
      annualAmt: 999,
      currencySymbol: "$",
      chipForMonthly: "Student 50% OFF",
      chipForAnnual: "Student 50% OFF",
      noOfOptionTrades: "45",
      discountValue: "16.67% discount",
      features: [
        { main: "All Silver features and" },
        { main: "Run OnDemand Feature" },
        { main: "Community Insights" },
        {
          main: "3 hours - Frequency - Market Watch & React",
          sub: ["Early Profit Booking", "Loss Protection Exit "],
        },
      ],
    },
    {
      title: "Platinum",
      logo: Platinum,
      whiteLogo: Platinum_,
      monthlyAmt: 149,
      annualAmt: 1499,
      currencySymbol: "$",
      chipForMonthly: "Student 50% OFF",
      chipForAnnual: "Student 50% OFF",
      noOfOptionTrades: "100 [after that 0.25$ per contract]",
      discountValue: "16.67% discount",
      features: [
        { main: "All Gold features and" },
        { main: "Control to Skip: Break Even Pricing" },
        {
          main: "15 min - Frequency - Market Watch & React",
          sub: ["Early Profit Booking", "Loss Protection Exit "],
        },
      ],
    },
    {
      title: "Institution",
      logo: Institution,
      whiteLogo: Institution_,
      features: [
        { main: "All Platinum features and" },
        { main: "1-1 Consultations with Options Professionals" },
        { main: "OnDemand Executions [During Market Hours]" },
      ],
    },
  ];

  const successMessage = 'Successfully Updated!';
  const successActivationMessage = 'Subscription is Activated successfully, Will be updated in the platform upon successful trail invoice amount.';
  const institutionMainTitle = 'Running an Institution ?';
  // const contactUSLink = '#';
  const contactUSLink = 'https://quantumtrades.com/contact-us/';
  const successUpgradeMessage = 'Subscription is Upgraded successfully. ';
  const successDowngradeMessage = 'Subscription is Downgraded successfully. ';
  const discountValue = '16.67';
  export {
    subscriptionPlans,
    successMessage,
    institutionMainTitle,
    contactUSLink,
    successActivationMessage,
    successUpgradeMessage,
    successDowngradeMessage,
    discountValue
  };
  