import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import CloseIcon from '@mui/icons-material/Close';
const CustomSwitch = styled((props, className) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme, className }) => ({
    width: 42,
    height: 22,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      
      "&.Mui-checked": {
        transform: "translateX(18px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#00BEFA",
          opacity: 1,
          border: 0,
          borderRadius: 22 / 2,
          "&:before, &:after": {
            content: '""',
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            width: 12,
            height: 16
          },
          "&:before": {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
              '#fff'
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 2
          },
          "&:after": {
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%red' d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E")`,

            right: 12
          }
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5
        }
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff"
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600]
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3
      }
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 18,
      height: 18,
      
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: className && className === 'showDisabledAsRed' ? 'red' :className === 'showDisabled'?'#cac2c252' : `#E9E9EA`,
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500
      }),
      "&:before, &:after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 12,
        height: 16
      },
      "&:after": {
        backgroundImage: className && className === 'showDisabledAsRed' ? 
        `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffffff' d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E")`
        :`url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 24 24'%3E%3Cpath fill='%238e8e93' d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E")`,
        color:'white',
        right: 8, bottom: -5
      }
    },
    ".Mui-disabled": {
      "& .MuiSwitch-thumb": {
        color: "#e2e2e2 !Important", // Set the color to red for the disabled state
      },
      
    },
    "& .MuiSwitch-thumb.Mui-disabled": {
      color: "#e2e2e2 !important", // Set the color to red for the disabled state
    },
  }));
  

export default function SwitchElement(props) {
  const { label, labelPlacement, defaultChecked, onChange, sx, className,disabled } = props;
  return (
    <FormGroup>
      <FormControlLabel
        control={<CustomSwitch sx={{ m: 1 }} defaultChecked={defaultChecked} className={className} />}
        label={label}
        labelPlacement={labelPlacement}
        onChange= {onChange}
        sx={{...sx, ml: 0}}
        disabled={disabled}
      />
    </FormGroup>
  );
}
