const config = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  basename: '',
  defaultPath: 'dashboard',
  fontFamily: `'Nunito Sans', sans-serif`,
  themeColor: 'light',
  borderRadius: 12,
  userAccountDetailsList: {},
  countriesList: [],
  defaultRecordsperPage: 5,
  oldApiSubscriptionKey: '692266ae802a49e2906508f8bfdb2af1',
  email: 'dev@quantumtrades.com',
  isRedirectAlertEnabled: false,
  stockScreenerUpdate:"https://qtcommonutils.azurewebsites.net/stock_screener/"
};

export default config;
