import { useState, useRef, useEffect, useLayoutEffect } from "react";
import countryCodes from "country-codes-list";
import { SET_COUNTRIES_LIST } from "store/actions";
import { useDispatch, useSelector } from "react-redux";

const Countries = () => {
    const dispatch = useDispatch();
 // Create a custom array with country code and country name (English)
 useLayoutEffect(() => {
    const myCountryCodes = countryCodes.customArray({
        value: '{countryCode}',
        label: '{countryNameEn}',
      });
      
      // Filter the list to include only the United States (country code: 'US')
      const countries = myCountryCodes.find((country) => country.value === 'US');
      
      dispatch({
        type: SET_COUNTRIES_LIST,
        countriesList: [countries],
      });

  }, []);
}





export default Countries;