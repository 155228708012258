import { Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import React, { memo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router";
import questionsJson from '../utils/questions.json'
import { isEmpty } from "validator";

const CustomMessage = () => {
  const theme = useTheme()
  const [questions, setQuestions] = useState(["How to trade in Stock market"])
  const pathName = useLocation().pathname

  useEffect(() => {
    if (questionsJson.length > 0 && isEmpty(pathName))
      setQuestions(
        questionsJson.pages.find((page) => pathName.includes(page.page))
          .questions
      );
  }, [pathName]);


  return (
    <Paper sx={{p:2, pb: 1, borderRadius: 2}}>
      <Typography variant="body2" sx={{...theme.typography.body1}}>
        You can ask me questions like these:
        <ul>
          {questions.map((q, index)=> <li key={index}>{q}</li>)}
        </ul>
      </Typography>
    </Paper>
  );
};

export default memo(CustomMessage);
