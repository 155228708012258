import React, { memo, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
// material-ui
import { useTheme } from "@mui/material/styles";
// project imports
import MainCard from "ui-component/cards/MainCard";
import Table from "ui-component/Table";
import { billingInvoiceHeaders as headCells } from "constants/TableHeaderLabels";
import * as Constants from "constants/Common";
import { getSubscriptionInvoice } from "commonApi";
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import { BrowserView, MobileView } from "react-device-detect";
import { Pagination, PaginationItem, Stack, Typography } from "@mui/material";
import Link from '@mui/material/Link';
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import Loader from "ui-component/Loader";
import RowsPerPageSelector from "ui-component/RowsPerPageSelector";
import { IconChevronsLeft, IconChevronsRight } from "@tabler/icons";


const Invoice = memo((props) => {
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5); // Initial rows per page
  const { superAdmin } = props;
  const selectedAccount = useSelector(
    state => state.customization.userAccountDetailsList.selectedAccount
  )
  const tableRef = React.createRef();

  useEffect(() => {
    getData();
  }, [superAdmin, selectedAccount]);

  function createData(
    invoiceNumber,
    invoiceDate,
    invoiceAmount,
    invoiceLink
  ) {
    return {
        invoiceNumber,
        invoiceDate,
        invoiceAmount,
        invoiceLink
    };
  }

  const getRows = (data) => {
    const rowData = data.map((item) => {
      return createData(
        item.invoice_id,
        item.billing_period_start,
        item.total,
        item.hosted_invoice_url
      );
    });
    setRows(rowData);
    const urls = data.map(item => item.hosted_invoice_url)
    setIsLoading(false);
  };

  const getData = async () => {
    setRows([]);
    setIsLoading(true);
    const data = await getSubscriptionInvoice(selectedAccount?.account_id, superAdmin)
    let tickers = [];
    if (data) {
      getRows(data);
    } else {
      setRows([]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // tableRef.current.scrollTop = 0;
    window.scrollTo(0, 0);
  };

  return (
    <MainCard
      title={Constants.pageTitle.invoice}
      sx={{ ...theme.typography.mainContainerHeader }}
      pageDescription={Constants.description.invoice}
    >
      {isLoading && <Loader />}
      <BrowserView>
        <Table
          enableCheckbox={false}
          headCells={headCells}
          rows={rows}
          tableTitle={Constants.pageTitle.invoice}
          enableToolBar={false}
          isSmallRows={true}
          loading={isLoading}
        />
      </BrowserView>
      <MobileView>
        <Grid container rowSpacing={0} ref={tableRef}>
          {
            rows.map((item, index) => 
              { 
                ((page-1)*rowsPerPage < (index + 1) && (index + 1) <= page*rowsPerPage)  && <Grid item md={12} sm={12} xs={12} xxs={12} rowSpacing={2} key={index} sx={{ width: '100%'}}>
                <Card sx={{
                  width: '100%',
                  overflowX: 'scroll',
                  bgcolor:
                    theme?.customization?.themeColor === "dark"
                      ? theme?.darkMode?.mainBackground
                      : theme.palette.primary.light, p: 3, my: 2
                }}>
                  <CardContent >
                  <Typography
                      variant="h3"
                      // component="div"
                      sx={{
                        x: 2,
                        pb: 2,
                        fontSize: { lg: "24px", md: "22px", xs: "20px" },
                        color: theme?.customization?.themeColor === "dark" && "#fff",
                      }}

                    >
                      Invoice Number : <Typography sx={{display: 'inline', textWrap: 'wrap'}} variant="string">{item.invoiceNumber}</Typography>
                    </Typography>
                    <Typography
                      variant="h3"
                      // component="div"
                      sx={{
                        x: 2,
                        pb: 2,
                        fontSize: { lg: "24px", md: "22px", xs: "20px" },
                        color: theme?.customization?.themeColor === "dark" && "#fff",
                      }}

                    >
                      Invoice Period: <Typography display={'inline'} variant="string">{item.invoiceDate}</Typography>
                    </Typography>
                    <Typography
                      variant="h3"
                      // component="div"
                      sx={{
                        x: 2,
                        pb: 2,
                        fontSize: { lg: "24px", md: "22px", xs: "20px" },
                        color: theme?.customization?.themeColor === "dark" && "#fff",
                      }}
                    >
                      Invoice Amount: <Typography display={'inline'} variant="string">{item.invoiceAmount}</Typography>
                    </Typography>
                    <Typography
                      variant="h3"
                      component="div"
                      sx={{
                        x: 2,
                        pb: 2,
                        fontSize: { lg: "24px", md: "22px", xs: "20px" },
                        color: theme?.customization?.themeColor === "dark" && "#fff",
                      }}

                    >
                      Invoice: <Typography display={'inline'} variant="string">
                      &nbsp;&nbsp;
                            <Link
                              href={item.invoiceLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              color={theme?.customization?.themeColor === "dark" ? "#00BEFA" : "#000"}
                              sx={{ mt: 1 }}
                            >
                              View
                            </Link>
                      </Typography>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            })
          }
          {!isLoading && rows.length > rowsPerPage && (
             <Stack
             direction="row"
             spacing={2}
             justifyContent="center"
             alignItems="center"
             sx={{ mt: 4, width: '100%' }}
           >
              <Pagination
                count={Math.ceil(rows.length / rowsPerPage)}
                page={page}
                onChange={handleChangePage}
                defaultPage={1}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{
                      previous: IconChevronsLeft,
                      next: IconChevronsRight,
                    }}
                    sx={{ display: rows.length > 1 ? "block" : "none" }}
                    {...item}
                  />
                )}
              />
            </Stack>
          )}
        </Grid>
      </MobileView>
    </MainCard>
  );
});

Invoice.propTypes = {};

export default Invoice;
