import * as React from "react";
import { Typography, Grid, Dialog, DialogTitle, IconButton, DialogContent, Box } from "@mui/material";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import SelectEle from "ui-component/SelectElement";
import Input from "ui-component/Input";

export default function HeaderFilter(props) {
  const theme = useTheme();

  return (
    <Dialog open={Boolean(props.anchorEl)} onClose={props.handleClose} fullWidth maxWidth="xs">
      <DialogTitle>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}
          sx={{ color: theme?.customization?.themeColor === "dark" && "#fff" }}
        >
          <Typography variant='h3'>Account Filter</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={props.handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={3} pb={3} md={12} lg={12} xs={12} xxs={12}>
          <Grid item md={4} lg={4} xs={12} xxs={12}>
            <Typography>Select Column:</Typography>
            <SelectEle
              labelId="Column"
              id={"select_column"}
              defaultValue={props.selectedColumn}
              handelChange={(newValue) => props.handleColumnSelect(newValue.target.value)}
              options={props.columns}
              disabledOptions={[]}
            />
          </Grid>
          <Grid item md={4} lg={4} xs={12} xxs={12}>
            <Typography>Select Condition:</Typography>
            <SelectEle
              labelId="Column"
              id={"select_condition"}
              defaultValue={props.selectedCondition}
              handelChange={(newValue) => props.handleConditionSelect(newValue.target.value)}
              options={props.conditions}
              disabledOptions={[]}
            />
          </Grid>
          <Grid item md={4} lg={4} xs={12} xxs={12}>
            <Typography>Value:</Typography>
            <Input
              labelId="Column"
              id={"value"}
              defaultValue={props.value}
              sx={{ width: "100%", "& legend": { display: "none" }, "& fieldset": { top: 0 } }}
              inputProps={{ "aria-label": "weight" }}
              isUpperCase={false}
              handleChange={(value) => props.handleValueChange(value)}
              type="text"
              lable={"Value"}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ justifyContent: "flex-end", paddingRight: 3 }} columnSpacing={2}>
          <Grid item>
            <Button color="inherit" sx={{ ...theme.buttons.primaryButton, width: 'max-content' }} onClick={props.handleClear}>Clear Filter</Button>
          </Grid>
          <Grid item>
            <Button color="inherit" sx={{ ...theme.buttons.primaryButton, width: 'max-content' }} onClick={props.handleClose}>Cancel</Button>
          </Grid>
          <Grid item>
            <Button color="primary" sx={{ ...theme.buttons.primaryButton, width: 'max-content' }} onClick={props.handleApply}>Apply</Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
