import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('pages/Authentication/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/Authentication/authentication/Register/index')));
const ForgotPassword = Loadable(lazy(() => import('pages/Authentication/authentication/ForgotPassword')));
const Logout = Loadable(lazy(() => import('pages/Authentication/authentication/Logout')));
//const AuthRegisterNew = Loadable(lazy(() => import('pages/Authentication/authentication/Register/index')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/login',
      element: <AuthLogin />
    },
    {
      path: '/register',
      element: <AuthRegister />
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />
    },
    {
      path: '/logout',
      element: <Logout />
    },
    // {
    //   path: '/register-new',
    //   element: <AuthRegisterNew />
    // },
  ]
};

export default AuthenticationRoutes;
