// assets
import { IconBrowserPlus, IconDeviceLaptop } from '@tabler/icons';
import { CanAccess } from 'constants/Common';

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const researchInsights = {
  id: 'pages',
  title: 'Research & Insights',
  type: 'group',
  children: [
    {
      id: 'community-insights',
      title: 'Community Insights',
      type: 'item',
      url: '/community-insights',
      icon: IconBrowserPlus,
      breadcrumbs: true,
      roles : {...CanAccess}
    },
    {
      id: 'stock-Screener',
      title: 'Stock Screener',
      type: 'item',
      url: '/stock-Screener',
      icon: IconDeviceLaptop,
      breadcrumbs: true,
      roles : {...CanAccess}
    },
  ]
};

export default researchInsights;
