/**
 * Button used in theme
 * @param {JsonObject} theme theme customization object
 */

export default function themeButtons(theme) {
    return {
        primaryButton: {
            background: '#00BEFA',
            border: '1px solid #00BEFA',
            borderRadius: '10px',
            padding: '8px 8px',
            fontSize: '15px',
            color: '#fff',
            cursor: 'pointer',
            width: '141px',
            height: '34px',
            lineHeight: '2px',
            fontWeight: 600,
            "&:hover": {
                border: '1px solid #00BEFA',
                color: '#00BEFA',
            },
            "&.Mui-disabled": {
                background: '#CED1D6',
                border: '1px solid #CED1D6',
                borderRadius: '10px',
                padding: '8px 8px',
                fontSize: '15px',
                color: '#fff',
                fontWeight: 600,
            }
        },
        secondaryButton: {
            background: '#CED1D6',
            border: '1px solid #CED1D6',
            borderRadius: '10px',
            padding: '8px 8px',
            fontSize: '15px',
            color: '#fff',
            width: '141px',
            height: '34px',
            lineHeight: '2px',
            fontWeight: 600,
            ":hover": {
                border: '1px solid #CED1D6',
                color: '#CED1D6',
                fontWeight: 600,
            }
        },
        subscriptionOutlinedButton: {
            background: '#fff',
            border: '1px solid #00BEFA',
            fontSize: '15px',
            color: '#00BEFA',
            cursor: 'pointer',
            lineHeight: '2px',
            padding: '21px',
            borderRadius: '23px',
            fontWeight: 600,
            "&:hover": {
                border: '1px solid #00BEFA',
                color: '#fff',
                background: '#00BEFA',
                fontWeight: 600,
            },
            "&.Mui-disabled": {
                background: '#fff',
                border: '1px solid #CED1D6',
                borderRadius: '23px',
                padding: '21px',
                fontSize: '15px',
                fontWeight: 600,
                color: '#CED1D6',
            }
        },
        subscriptionUpgradeButton: {
            background: '#00BEFA',
            border: '1px solid #00BEFA',
            fontSize: '15px',
            color: '#fff',
            cursor: 'pointer',
            lineHeight: '2px',
            padding: '21px',
            borderRadius: '23px',
            fontWeight: 600,
            "&:hover": {
                border: '1px solid #00BEFA',
                color: '#00BEFA',
                background: '#fff',
                fontWeight: 600,
            },
            "&.Mui-disabled": {
                background: '#fff',
                border: '1px solid #CED1D6',
                borderRadius: '23px',
                padding: '21px',
                fontSize: '15px',
                fontWeight: 600,
                color: '#CED1D6',
            }
        },
    }
}