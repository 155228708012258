import React from 'react'
import Logo from '../../../assets/images/quantum_Trades_Logo.png'
import { Paper } from '@mui/material'

const ChatbotIcon = () => {
  return (
    <Paper sx={{borderRadius: '50%', width: 40, height: 40, display: 'grid', placeItems: 'center', mr:2}}>
      <img src={Logo} alt="QT" width={25} height={25} style={{objectFit: 'cover'}}/>
    </Paper>
  )
}

export default ChatbotIcon