const appName = "Quantum Trades";
const switchButtonlabel = "Show Other trades (Including Rejected)";
const switchButtonlabel2 = "Show Other trades (Including Unsubscribed)";
const statusDropDownPlaceHolder = "Status";
const accountNameDropDownPlaceHolder = "Account Name";
const tickerDropDownPlaceHolder = "Ticker";
const decisionDropDownPlaceHolder = "Decision";
const dstatusDropDownPlaceHolder = "Status";
const dateRangePlaceholder = "Select Date Range";
const accountDropDownDefaultValue = "All Account";
const accountDropDownPlaceHolder = "Account";
const modeOfMgmt = "Mode of Mgmt";
const adminApprovedPlaceHolder = "Admin Approved";
const subscribedPlaceHolder = "Subscribed";
const updateEmail = "email";
const selectColumn = "Select Column";
const selectCondition = "Select Condition";
const qtTicker = "Ticker";
const qtTier = "Tier";
const qtDecision = "Decision";
const stDecision = "Stock Decision";
const wlDecision = "Wheel Decision";
const qtShortTerm = "Short Term";
const qtLongTerm = "Long Term";
const qtLongDecision = "Long Decision";
const qtTaApproved = "TA Approved";
const rsi = "RSI";
const QtFavourite = "QTFavourite";
const QtPreAuthorised = "QTPreAuthorised";

const footerText = {
  rights: "All Rights Reserved",
  privacy: "Privacy Policy",
  terms: "Terms of Use",
};
const pageTitle = {
  portfolio: "Portfolio",
  consolidatedDashboard: "Consolidated Dashboard",
  consolidatedPortfolio: "Consolidated Portfolio",
  consolidatedOrders: "Consolidated Orders",
  consolidatedAccounts: "Consolidated Balance",
  optionsActivity: "Options Activities",
  communityInsights: "Community Insights",
  invoice: "Invoices",
  balanceHistory: "Balance History",
  newAccount: "Add/Link New Account",
  stockScreener: "Stock Screener",
  accountManagement: "Account Management",
  qtSelection: "Qt Screener",
  strategyBasedAllocation: "Account - Strategy Based Allocation",
};

const TermDropDownList = [
  { label: "All", value: "" },
  {
    label: "BUY",
    value: "BUY",
  },
  {
    label: "SELL",
    value: "SELL",
  },
  {
    label: "HOLD",
    value: "HOLD",
  },
];
//  Add RSI as filter with Below >30 , < 30 , >70, < 70 , >30 & < 70 values

const rsiList = [
  {
    label: "All",
    value: "",
  },
  {
    label: ">30",
    value: ">30",
  },
  {
    label: "<30",
    value: "<30",
  },
  {
    label: ">70",
    value: ">70",
  },
  {
    label: "<70",
    value: "<70",
  },
  {
    label: ">30 & < 70",
    value: ">30 & < 70",
  },
];

const yesNo = [
  { label: "All", value: "" },
  {
    label: "True",
    value: "true",
  },
  {
    label: "False",
    value: "false",
  },
];

const modeOfMgmtList = [
  { label: "All", value: "" },
  {
    label: "Auto",
    value: "auto",
  },
  {
    label: "User",
    value: "user",
  },
];

const UserMode = [
  {
    label: "User",
    id: "user",
    value: "user",
  },
  {
    label: "Auto",
    id: "auto",
    value: "auto",
  },
  {
    label: "Risk Based",
    id: "riskbased",
    value: "riskbased",
  },
  {
    label: "Risk Based Apply",
    id: "riskbased-apply",
    value: "riskbased-apply",
  },
];

const newAccountAlert = {
  title: "Account Added",
  message: "Your account(s) have been added successfully",
};

const notes = {
  communityInsights:
    "Note: Community insights are generated starting with data from Aug-01-2023.",
};

const description = {
  dashboard:
    "The calculation of realized and unrealized gains is based on the execution of options contracts, including both buying and selling, according to specific strategies and selected tickers. It's important to note that this calculation does not include any additional income from interests or trades that are not conducted through our platform.",
  managePortfolio:
    "QuantumTrades uses your ticker selections and risk profile settings to generate options trades based on the wheel strategy. Select the tickers/stocks you want to hold long-term and your risk profile settings. The buy risk profile setting determines the price below the current market price at which you want to buy the stock through cash-secured puts. The sell risk profile setting determines the price above the current market price at which you are willing to sell the stock through covered calls",
  communityInsights: (
    <>
      Insights based on other QuantumTrades community members ticker selections
      and premiums received, considering risk profile settings,
      <br />
      <br /> This page can help traders make informed decisions about their own
      investments by providing insights into what other traders are doing.
      However, it is important to note that past performance is not indicative
      of future results and that traders should always do their own research
      before making any investment decisions.
      <br />
      {/* <div>
        <table>
          <thead>
            <tr>
              <td>item</td>
              <td>description</td>
            </tr>
          </thead>
          <tbody>
            <tr></tr>
          </tbody>
        </table>
      </div> */}
    </>
  ),
  portfolio:
    "Displays current brokerage account holdings, 15-minute delayed can be expected.",
  optionsActivity:
    "Displays all options trades executed for a given account, Select date range for more options activity ",
  invoice: "",
  subscription: "",
  stockScreener: "",
  accountManagement:
    "Displays all options trades executed for a given account, Select date range for more options activity ",
  qtSelection: "Page description",
};
const privacy_policy_link = "https://quantumtrades.com/privacy-policy/";
//const terms_link = "https://quantumtrades.com/terms-of-service/";
const terms_link = "https://quantumtrades.com/terms-and-conditions/";

const strategyDescription = {
  strategy_put_spread: "VERTICAL PUT SPREADS [CREDIT] - WEEKLY",
  strategy_call_spread: "VERTICAL CALL SPREADS [DEBIT] - WEEKLY",
  strategy_wheel: "CASH SECURED - COVERED CALLS [WHEEL] [DEBIT] - WEEKLY",
  strangle: "",
  straddle: "",
  iron_condore: "",
  custom_strategy: "",
};
const CanAccess = { superAdmin: true, supportAdmin: true };

export {
  appName,
  switchButtonlabel,
  switchButtonlabel2,
  pageTitle,
  statusDropDownPlaceHolder,
  accountNameDropDownPlaceHolder,
  tickerDropDownPlaceHolder,
  decisionDropDownPlaceHolder,
  dstatusDropDownPlaceHolder,
  dateRangePlaceholder,
  accountDropDownDefaultValue,
  accountDropDownPlaceHolder,
  footerText,
  notes,
  description,
  privacy_policy_link,
  terms_link,
  newAccountAlert,
  strategyDescription,
  modeOfMgmt,
  adminApprovedPlaceHolder,
  subscribedPlaceHolder,
  updateEmail,
  yesNo,
  modeOfMgmtList,
  CanAccess,
  selectColumn,
  selectCondition,
  TermDropDownList,
  qtTicker,
  qtDecision,
  qtLongTerm,
  wlDecision,
  stDecision,
  qtShortTerm,
  qtTaApproved,
  qtTier,
  rsiList,
  rsi,
  QtFavourite,
  qtLongDecision,
  QtPreAuthorised,
  UserMode,
};
