// assets
import { IconLayoutKanban, IconServer2, IconFileInvoice } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const supportAdminMenu = {
    id: 'support_admin',
    title: 'Support Admin',
    type: 'group',
    children: [
        {
            id: 'support-admin-invoices',
            title: 'Invoices',
            type: 'item',
            url: '/support-admin-invoices',
            icon: IconFileInvoice,
            breadcrumbs: true
        },
    ]
};

export default supportAdminMenu;
