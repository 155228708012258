import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton"; // Import IconButton
import CloseIcon from "@mui/icons-material/Close"; // Import Close icon
import { isMobile } from "react-device-detect";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: isMobile ? '550px' :'930px',
  // height: '550px',
  width: '92%',
  overflow: 'scroll'
};

export default function PrivacyPolicy({ isOpen, handleModel }) {
  const [open, setOpen] = React.useState(isOpen);
  const handleClose = () =>{
    handleModel()
    setOpen(false)
};

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <Stack sx={{mb:2}}>

                <Typography  variant="privacyPolicyTitle" >
                Privacy Policy
                </Typography>
                <IconButton
                aria-label="close"
                color="#5C6E9A"
                size="small"
                onClick={handleClose} // Add onClick handler to close the modal
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  backgroundColor: "transparent",
                  color: "#000",
                  m: 2
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack>
              <Typography variant="privacyPolicyContent">
                <p>
                  At Quantum Trades, we value the privacy of our users and are
                  committed to protecting it. This Privacy Policy explains how
                  we collect, use, and disclose information about you when you
                  use our Algorithmic Trading Platform.
                </p>
                <p>
                  By using the Service, you consent to our collection, use, and
                  disclosure of your personal data in accordance with this
                  Privacy Policy.
                </p>
                <p>
                  <strong>Personal Data We Collect</strong>
                  <br />
                  We may collect and process the following categories of
                  personal data about you:
                </p>
                <p class="nitro-offscreen">
                  <strong>Contact Information:</strong>&nbsp;We may collect your
                  name, email address, and phone number.
                </p>
                <p class="nitro-offscreen">
                  <strong>Transaction Information:</strong>&nbsp;We may collect
                  information about your transactions, including the amount,
                  date, time, and the instrument involved.
                </p>
                <p class="nitro-offscreen">
                  <strong>Trading Data:</strong>&nbsp;We may collect information
                  about your trading activities, including the type of trades
                  you make and the frequency of your trades.
                </p>
                <p class="nitro-offscreen">
                  <strong>Usage Information:</strong>&nbsp;We may collect
                  information about how you use the Service, including your IP
                  address, br/owser type, and the pages you visit.
                </p>
                <p class="nitro-offscreen">
                  <strong>
                    We may share your information with the following third
                    parties:
                  </strong>
                  <br />
                  Law enforcement agencies or other government entities when
                  required by law or in response to legal process
                </p>
                <p class="nitro-offscreen">
                  <strong>Your choices</strong>
                  <br />
                  You can control how we use your information by updating your
                  account settings or contacting us. You can also opt out of
                  marketing communications by following the instructions in
                  those communications.
                </p>
                <p class="nitro-offscreen">
                  <strong>Children’s Privacy</strong>
                  <br />
                  Our Platform is not intended for children under the age of 18,
                  and we do not knowingly collect information from children
                  under the age of 18.
                </p>
                <p class="nitro-offscreen">
                  <strong>Changes to this policy</strong>
                  <br />
                  We may update this Privacy Policy from time to time. We will
                  notify you of any changes by posting the new Privacy Policy on
                  this page.You are responsible for periodically visiting our
                  platform and this privacy policy to check for any changes.
                </p>
                <p class="nitro-offscreen">
                  <strong>Data Security</strong>
                  <br />
                  We have implemented measures designed to secure your personal
                  information from accidental loss and from unauthorized access,
                  use, alteration, and disclosure. We have SSL enabled, multi
                  factor auth with secured OAuth implemented within platform.
                </p>
                <p class="nitro-offscreen">
                  <strong>Contact us</strong>
                  <br />
                  If you have any questions or concerns about our Privacy
                  Policy, please contact us at&nbsp;
                  <a href="mailto:Support@QuantumTrades.com">
                    Support@QuantumTrades.com
                  </a>
                </p>
                <p class="nitro-offscreen">
                  By using our Algorithmic Trading Platform, you consent to our
                  collection, use, and disclosure of your information as
                  described in this Privacy Policy.
                </p>{" "}
              </Typography>
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
