export default function componentStyleOverrides(theme) {
  return {
    ".custom-daterangepicker": {
      backgroundColor: theme?.customization?.themeColor === "dark" ? theme?.darkMode?.mainBackground : theme.paper,
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "&.primaryAccountDropdown": {
            "& .MuiAutocomplete-input": {
              color: "#00BEFA",
              fontSize: "15px",
              fontWeight: 700,
              letterSpacing: "0.3px",
              lineHeight: "23px",
              cursor: "pointer",
              width: "100%",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#00BEFA",
            },
          },
          ".MuiAutocomplete-endAdornment .MuiSvgIcon-root":{
            color: theme?.customization?.themeColor === "dark" ? theme.paper : theme?.darkMode?.mainBackground,
            },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          ".Mui-focused": {
            top: "0px",
          },
          label: {
            top: "-5px",
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.darkHeaderText,
          padding: "0px",
          svg: {
            color: theme?.customization?.themeColor === "dark" ? '#fff' : '#0C1E5B',
          }
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: theme?.customization?.themeColor === "dark" && '#fff',
        },
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          ".MuiTableRow-root.MuiTableRow-head ": {
            backgroundColor: theme?.customization?.themeColor === "dark" ? theme?.darkMode.tableLabelBackground : theme.lightBlueBackground
          },
          ".MuiTableCell-root.MuiTableCell-head": {
            paddingLeft: "0",
            backgroundColor: theme?.customization?.themeColor === "dark" ? theme?.darkMode.tableLabelBackground : theme.lightBlueBackground,
            paddingTop: "14px",
            paddingBottom: "14px",
            borderBottom: "unset",
            "&:first-child": {
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
            },
            "&:last-child": {
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            },

            "&:nth-child(1)": {
              paddingLeft: "18px",
            },
          },
          ".MuiTableCell-body": {
            padding: "25px",
            paddingLeft: "0",
            color: theme?.customization?.themeColor === "dark" ? "#fff" : "#5C6E9A",
            fontSize: "14px",
            fontWeight: 700,
            letterSpacing: "0.24px",
            borderBottom: theme?.customization?.themeColor === "dark" ? `1px solid ${theme?.darkMode.mainBackground}` : "1px solid #EFF1F8",
            "&:nth-child(1)": {
              paddingLeft: "24px",
            },
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          marginTop: "20px",
          ul: {
            placeContent: "center",
          },
          ".MuiPaginationItem-root": {
            margin: "0 1px",
            backgroundColor: theme?.customization?.themeColor === "dark" ? '#5C687A' : theme.colors?.grey100,
            color: theme?.customization?.themeColor === "dark" ? '#fff' : '#0C1E5B',
          },
          ".MuiPaginationItem-previousNext": {
            paddingTop: "5px",
            backgroundColor: theme?.customization?.themeColor === "dark" ? 'unset' : theme.paper,
            color: theme.menuSelectedBack,
          },
          ".MuiPaginationItem-root.Mui-selected": {
            backgroundColor: theme.menuSelectedBack,
            color: theme.menuSelected,
            border: `1px solid ${theme.menuSelectedBack}`,
          },
          ".MuiPaginationItem-root.Mui-selected:hover": {
            backgroundColor: theme.menuSelectedBack,
            color: theme.menuSelected,
            border: `1px solid ${theme.menuSelectedBack}`,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        primary: {
          fontWeight: 500,
          borderRadius: "4px",
          backgroundColor: theme.menuSelectedBack,
          border: `1px solid ${theme.menuSelectedBack}`,
          color: theme.menuSelected,
          "&.Mui-selected": {
            color: theme.menuSelected,
            border: `1px solid ${theme.menuSelectedBack}`,
            backgroundColor: theme.menuSelectedBack,
            //theme.menuSelectedBack,
            borderRadius: "4px",
            "&:hover": {
              backgroundColor: theme.menuSelectedBack,
              color: theme.menuSelected,
              border: `1px solid ${theme.menuSelectedBack}`,
              borderRadius: "4px",
            },
            "& .MuiListItemIcon-root": {
              "& .icon-tabler": {
                stroke: theme.menuSelected,
              },
            },
          },
          "&:hover": {
            backgroundColor: theme.menuSelectedBack,
            color: theme.menuSelected,
            border: `1px solid ${theme.menuSelectedBack}`,
            borderRadius: "4px",
            "& .MuiListItemIcon-root": {
              color: theme.menuSelected,
            },
          },
        },
        disabled: {
          fontWeight: 500,
          borderRadius: "4px",
          backgroundColor: theme.disabledButton,
          border: `1px solid ${theme.disabledButton}`,
          color: theme.paper,
        },
        root: {},
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          background: theme?.customization?.themeColor === "dark" ? theme?.darkMode?.sectionBackground : theme.paper,
          borderColor: theme?.customization?.themeColor === "dark" ? `${theme?.darkMode?.sectionBackground} !important` : `${theme.paper} !important` ,
          backgroundImage: "none",
          "&.dashboardPage": {
            borderRadius: "0px",
            "& .customActionSection": {
              "& .actionItemBlock": {
                background: theme?.customization?.themeColor === "dark" ? theme?.darkMode?.sectionBackground : theme.paper,
                borderRadius: "16px",
                padding: "0px",
                "& .MuiAlert-message": {
                  width: "100%",
                  "& .redirectIcon": {
                    background: theme.colors?.primaryMain,
                    color: theme.paper,
                    borderRadius: "20px",
                    cursor: "pointer",
                  },
                  "& .closeicon": {
                    background: "#A8A8A8",
                    color: theme.paper,
                    borderRadius: "20px",
                    padding: "4px",
                    marginLeft: "20px",
                    cursor: "pointer",
                  },
                  "& .MuiAlertTitle-root": {
                    fontSize: "20px",
                    fontWeight: "Bold",
                    color: theme.colors?.primaryMain,
                  },
                  "& .description": {
                    fontSize: "14px",
                    fontWeight: "regular",
                    color: theme?.customization?.themeColor === "dark" ? "#fff" : theme.colors?.grey600,

                  },
                },
                "& .MuiAlert-icon": {
                  background: theme.colors?.primaryMain,
                  color: theme.paper,
                  borderTopLeftRadius: "16px",
                  borderBottomLeftRadius: "16px",
                  padding: "15px",
                  paddingLeft: "5px",
                  svg: {
                    fontSize: "30px",
                  },
                },
              },
            },
          },
          "&.profilePage": {
            height: "100%",
            position: "relative",
            "& .MuiCardContent-root": {
              padding: "12px 0",
            },
            "& .stepperFormButtonSection": {
              left: "0",
              position: "absolute",
              bottom: "20px",
              right: "0",
            },
            "& .isRequired": {
              display: "flex",
              alignItems: "center",
              "&::after": {
                content: '"*"',
                color: "red",
                marginLeft: "2px",
              },
            },
            "& .numberedList": {
              counterReset: "item-counter",
              background: theme?.customization?.themeColor === "dark" && '#5C687A',

              ".listItem": {
                position: "relative",
                alignItems: "normal",
              },

              ".listItem p": {
                marginLeft: "18px",
              },

              ".listItem:before": {
                content: 'counter(item-counter) "."',
                counterIncrement: "item-counter",
                position: "absolute",
                fontWeight: "bold",
                color: "#5C6E9A",
                fontSize: "14px",
              },
            },
          },
        },
        rounded: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "26px 0",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },

    MuiListItem: {
      styleOverrides: {
        root: {
          "&::before":{
            color: theme?.customization?.themeColor === "dark" && '#fff !important'
          }
        }
      }
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          color:
            theme?.customization?.themeColor === "dark"
              ? theme?.darkMode.whiteFont
              : theme.menuText,
          paddingTop: "10px",
          paddingBottom: "10px",
          fontWeight: "100",
          "&.Mui-selected": {
            color: theme.menuSelected,
            border: theme.menuSelectedBack,
            backgroundColor: theme.menuSelectedBack,
            //theme.menuSelectedBack,
            borderRadius: "22px",
            "&:hover": {
              backgroundColor: theme.menuSelectedBack,
              color: theme.menuSelected,
              border: theme.menuSelectedBack,
              borderRadius: "22px",
            },
            "& .MuiListItemIcon-root": {
              "& .icon-tabler": {
                stroke: theme.menuSelected,
              },
            },
          },
          "&:hover": {
            backgroundColor: theme?.customization?.themeColor === "dark" ? 'unset !Important' : theme.menuSelectedBack,
            color: theme.menuSelected,
            border: theme.menuSelectedBack,
            borderRadius: "22px",
            "& .MuiListItemIcon-root": {
              "& .icon-tabler": {
                stroke: theme.menuSelected,
              },
            },
            " .MuiListItemText-root .MuiTypography-root ": {
              color: theme.menuSelected,
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          minWidth: "36px",
          svg: {
            stroke:
              theme?.customization?.themeColor === "dark"
                ? theme?.darkMode.whiteFont
                : theme.menuText,
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.menuText,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.textDark,
          "&::placeholder": {
            color: theme.darkTextSecondary,
            fontSize: "0.875rem",
          },
        },
      },
    },
   
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: theme?.customization?.themeColor === "dark" ? theme?.darkMode?.mainBackground : theme.paper,
          borderRadius: "8px",
          height: "40px",
          background: theme?.customization?.themeColor === "dark" && '#272E34 !important',
          "& .MuiOutlinedInput-notchedOutline ": {
            border: "1px solid #D8D8D8",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${theme.primaryMain}`,
          },
          " .MuiAutocomplete-input": {
            padding: "1.5px 4px 7.5px 5px !important",
            color: theme?.customization?.themeColor === "dark" && '#fff'
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.colors.grey,
          },
          "&:hover $notchedOutline": {
            borderColor: theme.colors?.primaryLight,
          },
          "&.MuiInputBase-multiline": {
            padding: 1,
          },
        },
        input: {
          fontWeight: 500,
          background: theme?.customization?.themeColor === "dark" ? theme?.darkMode?.mainBackground : theme.paper,
          color: theme?.customization?.themeColor === "dark" && 'white',
          padding: "15.5px 14px",
          borderRadius: `6px`,
          "&.MuiInputBase-inputSizeSmall": {
            padding: "10px 14px",
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
          "&.Mui-disabled":{
            "-webkit-text-fill-color": theme?.customization?.themeColor === "dark" && 'white !important',
            color: theme?.customization?.themeColor === "dark" && 'white !important',
          }
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          borderRadius: `6px`,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: theme?.customization?.themeColor === "dark" ? '#fff' :theme.colors?.grey300,
          },
        },
        mark: {
          backgroundColor: theme.paper,
          width: "4px",
        },
        valueLabel: {
          color: theme?.colors?.primaryLight,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.divider,
          opacity: 1,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: theme.colors?.primaryDark,
          background: theme.colors?.primary200,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: "11px",
          fontWeight: "600",
          letterSpacing: "0.22px",
          textAlign: "center",
          borderRadius: "8px",
          height: "26px",
          textTransform: "uppercase",
          lineHeight: "12px",
          width: "max-content",
          alignSelf: "center",
          "&.MuiChip-colorDefault": {
            backgroundColor: "#E3EBFF",
            color: "#5C6E9A",
          },
          "&.MuiChip-colorSuccess": {
            backgroundColor: "#D6FFD6",
            color: "#2CA10F",
          },
          "&.MuiChip-colorError": {
            backgroundColor: "#FEDBFD",
            color: "#B956B6",
          },
          "&.MuiChip-deletable .MuiChip-deleteIcon": {
            color: "inherit",
          },
          "&.silverSubscription": {
            color: "#888FA7",
            textTransform: "capitalize",
            background: "#fff",
            fontSize: "12px",
            fontWeight: "400",
            borderRadius: "15px",
            lineHeight: "14px",
          },
          "&.goldSubscription": {
            color: "#FF8008",
            textTransform: "capitalize",
            background: "#fff",
            fontSize: "12px",
            fontWeight: "400",
            borderRadius: "15px",
            lineHeight: "14px",
          },
          "&.platinumSubscription": {
            color: "#0EC8EB",
            textTransform: "capitalize",
            background: "#fff",
            fontSize: "12px",
            fontWeight: "400",
            borderRadius: "15px",
            lineHeight: "14px",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.paper,
          background: theme.colors?.grey700,
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          svg: {
            color: theme?.customization?.themeColor === "dark" ? '#fff !important' : '#0C1E5B',
          }
        }
      }
    },
    MuiFormLabel:{
      styleOverrides: {
        root: {

          "&.MuiFormLabel-colorPrimary":{
            color: theme?.customization?.themeColor === "dark" && '#fff !important'
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        darkModeText: {
          color: "#5C6E9A",
        },
        body1:{
          color: theme?.customization?.themeColor === "dark" && '#fff !important'
        },
        caption: {
          color: theme?.customization?.themeColor === "dark" ? '#93E5FF !important' : '#CED1D6',
        },
        privacyPolicyTitle: {
          color: "#5C6E9A",
          fontSize: "28px",
          fontWeight: 700,
          lineHeight: "1.334em",
          alignSelf: "center",
        },
        privacyPolicyContent: {
          "& p": {
            color: "#5C6E9A",
            fontSize: "19px",
            fontWeight: 500,
            lineHeight: "1.334em",
            marginBottom: "12px",
            "& strong": {
              fontSize: "21px",
              fontWeight: 700,
            },
          },
        },
        copyrightText: {
          color: theme.colors?.grey600,
          textAlign: "center",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "normal",
          letterSpacing: "0.875px",
          "& .copyrightLink": {
            color: theme.colors?.grey600,
            textAlign: "center",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            letterSpacing: "0.875px",
            textDecoration: "underline",
          },
        },
        membershipPlanNameText: {
          color: theme?.customization?.themeColor === "dark" ? '#fff !important' : theme.messageText,
          fontSize: "32px",
          fontWeight: 400,
          lineHeight: "23px",
          letterSpacing: "0.16px",
        },
        membershipPlanNameTextMobile: {
          color: theme?.customization?.themeColor === "dark" ? '#fff !important' : theme.messageText,
          fontSize: "15px",
          fontWeight: 400,
          lineHeight: "18px",
          letterSpacing: "0.16px",
        },
        membershipPlanTitleText: {
          color: theme?.customization?.themeColor === "dark" ? '#fff !important' : theme.messageText,
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "23px",
          letterSpacing: "0.16px",
        },
        tableHeader: {
          color: theme.darkHeaderText,
          fontSize: "1.5em",
          fontWeight: 700,
          letterSpacing: "0.48px",
        },
        messageHeader: {
          color: theme?.customization?.themeColor === "dark" ? '#fff !important' : theme.messageText,
          fontSize: "1.08em",
          fontWeight: 700,
          letterSpacing: "0.26px",
        },
        messageContent: {
          color: theme?.customization?.themeColor === "dark" ? '#fff !important' : theme.messageText,
          fontSize: "1.08em",
          fontWeight: 400,
        },
        messageHighlightedContent: {
          color: theme.hightlightedMessageRed,
          fontSize: "1.08em",
          fontWeight: 400,
        },
        settingCardHeader: {
          color: "#0C1E5B",//theme.darkHeaderText,
          fontSize: "16px",
          fontWeight: 400,
        },
        settingCardAmount: {
          color: theme.highlightedAmount,

          fontWeight: 600,
        },
        settingCardHeading: {
          color: theme.darkHeaderText,
          fontWeight: 700,
          lineHeight: "normal",
          marginTop: "18px",
        },
        settingCardAccountName: {
          color: theme?.customization?.themeColor === "dark" ? '#fff' : theme.darkHeaderText,
          fontWeight: 700,
          lineHeight: "normal",
          fontSize: 20,
        },
        settingCardAccountNumber: {
          color: theme.darkHeaderText,
          fontWeight: 500,
          lineHeight: "normal",
          fontSize: 20,
          letterSpacing: 3,
        },
        tableColumnExtraHeaderLabel: {
          color: theme.darkHeaderText,
          fontSize: "12px",
          fontWeight: 700,
          letterSpacing: "0.24px",
        },
        tableColumnHeaderLabel: {
          color: theme?.customization?.themeColor === "dark" ? '#fff' : theme.darkHeaderText,
          fontSize: "16px",
          fontWeight: 600,
          letterSpacing: "0.24px",
        },
        tableInlineEditText: {
          color: theme.menuSelectedBack,
          fontWeight: 700,
          fontSize: "15px",
          letterSpacing: "0.3px",
        },
        pageHeaderText: {
          color:
            theme?.customization?.themeColor === "dark"
              ? theme?.darkMode.whiteFont
              : theme.darkHeaderText,
          fontWeight: 700,
          letterSpacing: "0.4px",
        },
        subscriptionTotalAmount: {
          color: "#FFF",
          fontSize: "40px",
          fontWeight: 400,
          lineHeight: "40px",
          display: "flex",
          "& p": {
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "29px",
            marginRight: "2px",
          },
        },
        subscriptionPeriodlabel: {
          color: "#FFF",
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "23px",
        },
        subscriptionDiscountPercentage: {
          color: "#FFF",
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "10px",
          display: "flex",
          paddingLeft: "5px",
          paddingTop: "5px",
          width: "fit-content",
        },
        subscriptionPerContractText: {
          color: "#FFF",
          fontSize: "16px",
          fontWeight: 600,
          lineHeight: "12px",
        },
        subscriptionCurrentPlan: {
          position: "absolute",
          background: theme.colors?.primaryMain,
          borderBottomRightRadius: "70px",
          borderBottomLeftRadius: "70px",
          width: "90px",
          height: "48px",
          textAlign: "center",
          lineHeight: "13px",
          padding: "9px",
          color: "#fff",
          fontSize: "13px",
          marginTop: "-6px",
        },
        subscriptionInstitutionPeriodlabel: {
          color: "#FFF",
          textAlign: "center",
          fontSize: "32px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "40px",
        },
        subscriptionInstitutionPeriodSublabel: {
          color: "#FFF",
          textAlign: "center",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "30px",
          "& a": {
            color: "#FFF",
            textAlign: "center",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "30px",
            textDecorationLine: "underline",
            cursor: "pointer",
            "&:hover": {
              color: "#fff",
            },
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          "&.MuiAlert-standardSuccess": {
            backgroundColor: "#2ab627",
            fontSize: "18px",
            color: "#fff",
            fontWeight: 700,
            "& .MuiAlert-icon": {
              color: "#fff",
              alignSelf: "center",
            },
            "& .MuiAlert-action ": {
              alignItems: "center",
              marginTop: "-3px",
            },
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "15px 0",
          "& .apexcharts-text": {
            fill: theme?.customization?.themeColor === "dark" ? '#fff' : theme.darkHeaderText,
          },
          "& .apexcharts-text tspan": {
            color: 'red'
          }
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          "&.SettingsPopupIcons .MuiIconButton-colorPrimary": {
            width: "30px",
            height: "30px",
            borderRadius: "20px",
            color: "#fff",
            background: theme.colors?.primaryMain,
          },
          "&.SettingsPopupIcons .MuiIconButton-colorSecondary": {
            width: "30px",
            height: "30px",
            borderRadius: "20px",
            color: "#fff",
            backgroundColor: "#A8A8A8",
          },
          ".SettingsPopup": {
            marginTop: "1em !important",
            padding: "10px",
            boxShadow:
              "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            '&[data-popper-placement*="bottom"] .MuiTooltip-arrow': {
              marginTop: "-2em",
            },
            ".MuiTooltip-tooltipPlacementBottom": {
              background: theme.paper,
              color: theme.darkHeaderText,
              marginTop: "unset !important",
            },
            ".MuiTooltip-arrow": {
              width: "3em",
              height: "2em",
              color: theme.darkHeaderText,
            },
          },
        },
      },
    },
  };
}
