import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import BillingPortal from "pages/BillingPortal";
import Success from "pages/RedirectingPages/Success";
import { element } from "prop-types";

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import("pages/Dashboard")));
const ConsolidatedDashboard = Loadable(
  lazy(() => import("pages/Consolidated"))
);
const Portfolio = Loadable(lazy(() => import("pages/Portfolio")));
const AccountMgmt = Loadable(lazy(() => import("pages/AccountManagement")));
const OptionsActivities = Loadable(
  lazy(() => import("pages/OptionsActivities"))
);
const SubscriptionPlan = Loadable(lazy(() => import("pages/Subscription")));
const CommunityInsights = Loadable(
  lazy(() => import("pages/CommunityInsights"))
);
const Invoices = Loadable(lazy(() => import("pages/Invoice")));
const Profile = Loadable(lazy(() => import("pages/Profile")));
const ManagePortfolio = Loadable(lazy(() => import("pages/ManagePortfolio")));
const ManagePortfoliov2 = Loadable(lazy(() => import("pages/ManagePortfolio_v2")));
const BalanceHistory = Loadable(lazy(() => import("pages/BalanceHistory")));
const AddLinkNewAccount = Loadable(lazy(() => import("pages/NewAccount")));
const ManagePortfolioNew = Loadable(
  lazy(() => import("pages/ManagePortfolioNew"))
);
const StockScreener = Loadable(lazy(() => import("pages/StockScreener")));
const QtSelection = Loadable(lazy(() => import("pages/QtSelection")));
const StrategyBasedAllocation = Loadable(
  lazy(() => import("pages/StrategyBasedAllocation"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <DashboardDefault superAdmin={true} />,
    },
    {
      path: "dashboard",
      element: <DashboardDefault superAdmin={true} />,
    },
    {
      path: "options-activities",
      element: <OptionsActivities superAdmin={true} />,
    },
    {
      path: "consolidated-dashboard",
      element: <ConsolidatedDashboard superAdmin={true} />,
    },
    {
      path: "portfolio",
      element: <Portfolio superAdmin={true} />,
    },
    {
      path: "manage-portfolio",
      element: <ManagePortfolio superAdmin={true} />,
    },
    {
      path: "manage-portfolio-v2",
      element: <ManagePortfoliov2 superAdmin={true} />,
    },
    {
      path: "billing",
      element: <BillingPortal superAdmin={true} />,
    },
    // {
    //   path: 'subscriptions',
    //   element: <SubscriptionPlan />
    // },
    {
      path: "community-insights",
      element: <CommunityInsights superAdmin={true} />,
    },
    // {
    //   path: 'invoices',
    //   element: <Invoices />
    // },
    {
      path: "/billing/success",
      element: <Success superAdmin={true} />,
    },
    {
      path: "/profile",
      element: <Profile superAdmin={true} />,
    },
    {
      path: "manage-portfolio",
      element: <ManagePortfolio superAdmin={true} />,
    },
    {
      path: "/admin-portfolio",
      element: <Portfolio superAdmin={true} />,
    },
    {
      path: "/admin-options-activities",
      element: <OptionsActivities superAdmin={true} />,
    },
    {
      path: "/admin-invoices",
      element: <Invoices superAdmin={true} />,
    },
    {
      path: "/balance-history",
      element: <BalanceHistory superAdmin={true} />,
    },
    {
      path: "/support-admin-invoices",
      element: <Invoices superAdmin={true} />,
    },
    {
      path: "/add-link-account",
      element: <AddLinkNewAccount superAdmin={true} />,
    },
    {
      path: "/manage-portfolio-new",
      element: <ManagePortfolioNew superAdmin={true} />,
    },
    {
      path: "/admin-balance-history",
      element: <BalanceHistory superAdmin={true} />,
    },
    {
      path: "/stock-Screener",
      element: <StockScreener superAdmin={true} />,
    },
    {
      path: "/account-management",
      element: <AccountMgmt />,
    },
    {
      path: "/qt-selection",
      element: <QtSelection />,
    },
    {
      path: "/strategy-based-allocation",
      element: <StrategyBasedAllocation />,
    },
    // {
    //   path: 'utils',
    //   children: [
    //     {
    //       path: 'util-color',
    //       element: <UtilsColor />
    //     }
    //   ]
    // },
  ],
};

export default MainRoutes;
