import { OutlinedInput } from "@mui/material";
import { shouldForwardProp } from "@mui/system";
import { useTheme, styled } from "@mui/material/styles";

// ==============================|| CUSTOM SELECT ||============================== //
const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    paddingLeft: 16,
    paddingRight: 16,
    "& input": {
      background: "transparent !important",
      paddingLeft: "4px !important",
    },
    [theme.breakpoints.down("lg")]: {
      maxWidth: 250,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      background: "#fff",
    },
  })
);
const InputElement = (props) => {
  const { placeholder, value, handleChange, defaultValue, sx, isUpperCase,type,onFocuse,onBlur,lable } = props;
  const onChangeInput = (event) => {
    if(isUpperCase){
      handleChange(event.target.value.toUpperCase());
    }else{
      handleChange(event.target.value);
      
    }
  };
  return (
    <OutlineInputStyle
      value={defaultValue}
      placeholder={placeholder}
      onChange={onChangeInput}
      defaultValue = {defaultValue}
      sx={{...sx}}
      type={type}
      onFocus={onFocuse}
      onBlur={onBlur!== undefined? onBlur:null}
      label={lable}
    />
  );
};

export default InputElement;
