import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import {
  Select,
  MenuItem,
  FormControl
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
// ==============================|| CUSTOM SELECT ||============================== //


const SelectEle = forwardRef((props, ref) => {
  const theme = useTheme();
  const {
    options,
    label,
    handelChange,
    defaultValue,
    id,
    disabledOptions
  } = props;
  return (
    <FormControl sx={{width:'100%'}} >
        <Select
            labelId="demo-simple-select-label"
            id={id}
            value={defaultValue}
            className={`customSelectElement ${theme?.customization?.themeColor === "dark" ? 'selectDropdown' : 'selectDropdownLight'}` }
            onChange={handelChange}
        >
        {
            options.map(option => (
            <MenuItem value={option.id} disabled={disabledOptions.map(e => e.id).indexOf(option.id) > -1 ? true: false}>{option.label}</MenuItem>
            ))
        }
        </Select>
        
    </FormControl>
  );
});


export default SelectEle;
