import PropTypes from "prop-types";
import { forwardRef } from "react";

// material-ui
import { styled } from "@mui/material/styles";
import { Autocomplete, TextField, Paper, List } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { GlobalStyles } from '@mui/material';

// ==============================|| CUSTOM SELECT ||============================== //
const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    display: "none",
    color: theme?.customization?.themeColor === "dark" ? theme.paper : theme?.darkMode?.mainBackground,
  },
  ".MuiInputLabel-root:not(.Mui-expanded)": {
    display: "block",
    color: theme?.customization?.themeColor === "dark" ? theme.paper : theme?.darkMode?.mainBackground,
  },
  " .MuiAutocomplete-tag ":{
    color: theme?.customization?.themeColor === "dark" ? '#fff' : theme?.darkMode?.mainBackground,
  }
}));

const globalScrollBarStyles = <GlobalStyles styles={{
  '.MuiAutocomplete-listbox::-webkit-scrollbar': {
    width: '10px',
  },
  '.MuiAutocomplete-listbox::-webkit-scrollbar-track': {
    backgroundColor: '#f0f0f0',
  },
  '.MuiAutocomplete-listbox::-webkit-scrollbar-thumb': {
    backgroundColor: '#00b7fe',
    borderRadius: '10px',
    border: '3px solid rgb(255 255 255)',
  }
}} />;

const SelectElement = forwardRef((props, ref) => {
  const theme = useTheme();

  const {
    width,
    label,
    itemList,
    value,
    handleChange,
    allowClear,
    defaultValue,
    className,
    isMultiple,
    getOptionDisabled,
    displayLabel,
    disabled=false
  } = props;
  return (
    <Autocomplete
      multiple={isMultiple ? isMultiple : false}
      limitTags={1}
      disablePortal
      disableClearable={!allowClear}
      value={defaultValue}
      options={itemList}
      disabled={disabled}
      sx={{ width: width }}
      onChange={handleChange}
      getOptionDisabled={getOptionDisabled}
      className={`customSelectElement ${className}`}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          label={label}
          sx={{ "& legend": { display: displayLabel?"block":"none" }, "& fieldset": { top: 0 }, }}
          value={defaultValue}
        />
      )}
      PaperComponent={({ children }) => (
        <Paper
          sx={{
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
            border: "1px solid #DFE0EB",
            borderRadius: "4px",
          }}
        >
          {globalScrollBarStyles}
          {children}
        </Paper>
      )}
      renderOption={(props, option, state) => (
        <li
          {...props}
        className="dropdownItemsList"
          style={{
            backgroundColor: theme?.customization?.themeColor === "dark" ? (state.selected ? "#00BEFA" : theme.darkMode.mainBackground)  : (state.selected ? "#00BEFA" : "white"),
            color: theme?.customization?.themeColor === "dark" ? "white"  : (state.selected ? "white" : theme.primaryTextColor),
            cursor: "pointer",
            borderBottom: theme?.customization?.themeColor === "dark" ? `1px solid ${theme.darkMode.sectionBackground}` : `1px solid #DFE0EB`,
            padding: "12px 14px",
          }}
          value
        >
          {option.label}
        </li>
      )}
      noOptionsText="No Records"
    />
  );
});

SelectElement.propTypes = {
  width: PropTypes.number,
  label: PropTypes.string,
  itemList: PropTypes.array,
  value: PropTypes.string,
  handleChange: PropTypes.func,
};

export default SelectElement;
