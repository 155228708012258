import PropTypes from "prop-types";
import { forwardRef, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

// project imports
import { MENU_OPEN, SET_MENU,SET_REDIRECT_ALLOW_ENABLED } from "store/actions";

// assets
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ConfirmationDialog from '../../../../../ui-component/ConfirmationDialog'
// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isRedirectAlertEnabled = useSelector(
    (state) => state.customization.isRedirectAlertEnabled
  );
  const navigate = useNavigate();

  const customization = useSelector((state) => state.customization);
  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));
  const Icon = item.icon;
  const itemIcon = item?.icon ? (
    <Icon stroke={1.5} size="1.3rem" color={theme.palette.primary.menuIcons} />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width:
          customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
        height:
          customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
      }}
      fontSize={level > 0 ? "inherit" : "medium"}
    />
  );

  let itemTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={item.url} target={itemTarget} />
    )),
  };
  if (item?.external) {
    listItemProps = { component: "a", href: item.url, target: itemTarget };
  }

  const [open, setOpen] = useState(false);
  const [currentID, setCurrentID] = useState(null);

  const handleConfirm = (id) => {
    dispatch({ type: MENU_OPEN, id });
    if (matchesSM) {
      dispatch({ type: SET_MENU, opened: true });
    }
    dispatch({
      type: SET_REDIRECT_ALLOW_ENABLED,
      isRedirectAlertEnabled: false,
    });
    setOpen(false);
    navigate(item.url);
    
  };

  const handleCancel = (event) => {
    event.preventDefault();
    setOpen(false);
  };

  const itemHandler = (event, id) => {
    setCurrentID(id);
    const isManagePortfolio = id !== "Manage Portfolio";

    if (isManagePortfolio && isRedirectAlertEnabled) {
      event.preventDefault();
      setOpen(true);
    } else {
      dispatch({ type: MENU_OPEN, id });
      if (matchesSM) dispatch({ type: SET_MENU, opened: true });
    }
  };

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch({ type: MENU_OPEN, id: item.id });
    }
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <>
    <ConfirmationDialog open={open} handleCancel={handleCancel} handleOk={handleConfirm} action='' page={'manage_portfolio'} content ={`Are you sure you want to go to the ${currentID} page? Changes will be lost until it is submitted.`}  />
    
      <ListItemButton
        {...listItemProps}
        disabled={item.disabled}
        sx={{
          borderRadius: `${customization.borderRadius}px`,
          mb: 0.5,
          alignItems: "flex-start",
          backgroundColor: level > 1 ? "transparent !important" : "inherit",
          py: level > 1 ? 1 : 1.25,
          pl: `${level * 24}px`,
        }}
        selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
        onClick={(event) => itemHandler(event, item.id)}
      >
        <ListItemIcon sx={{ my: "auto", minWidth: !item?.icon ? 18 : 36 }}>
          {itemIcon}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              variant={
                customization.isOpen.findIndex((id) => id === item.id) > -1
                  ? "h5"
                  : "body1"
              }
              color="inherit"
            >
              {item.title}
            </Typography>
          }
          secondary={
            item.caption && (
              <Typography
                variant="caption"
                sx={{ ...theme.typography.subMenuCaption }}
                display="block"
                gutterBottom
              >
                {item.caption}
              </Typography>
            )
          }
        />
        {item.chip && (
          <Chip
            color={item.chip.color}
            variant={item.chip.variant}
            size={item.chip.size}
            label={item.chip.label}
            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          />
        )}
      </ListItemButton>
     </>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
