import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import moment from 'moment';
import * as CommonConstants from '../../constants/Common';
// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  Typography,
  useMediaQuery,
  Container,
  Stack
} from "@mui/material";

// project imports
import Breadcrumbs from "ui-component/extended/Breadcrumbs";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Customization from "../Customization";
import navigation from "menu-items";
import { drawerWidth } from "store/constant";
import { SET_MENU } from "store/actions";
import * as Constants from "constants/Common";
import packageJson from '../../../package.json';

// assets
import { IconChevronRight } from "@tabler/icons";

import PrivacyPolicy from "ui-component/PrivacyPolicy"
import TermsAndCondition from "ui-component/TermsAndCondition"
import AccountDropDownSection from "./Header/AccountDropDownSection";
import ChatBot from 'layout/ChatBot.js';

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create(
      "margin",
      open
        ? {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }
        : {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }
    ),
    [theme.breakpoints.up("md")]: {
      marginLeft: open ? 0 : -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
      marginRight: "10px",
    },
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };
  const [cookies, setCookie] = useCookies(['login_session']);
  const [isPrivacyopen, setIsPrivacyopen] = useState(false);
  const [isTermsCondition, setIsTermsCondition] = useState(false);

  useEffect(() => {
    if (cookies.login_session === undefined || moment() > moment(cookies.login_session.expiredAt)) {
      navigate('/login', { replace: true });
    }
  }, []);

  const handlePrivacyModel = () => {
    setIsPrivacyopen(!isPrivacyopen)
  }
  const handleTermsModel = () => {
    setIsTermsCondition(!isTermsCondition)
  }

  return (
    <Box sx={{ display: "flex", minHeight: "100vh",
      background: theme?.customization?.themeColor === "dark" ? theme?.darkMode.sectionBackground : theme.palette.background.default, overflowX:"auto", width:"100%"
    }}>
      <PrivacyPolicy isOpen={isPrivacyopen} handleModel={handlePrivacyModel} />
      <TermsAndCondition isOpen={isTermsCondition} handleModel={handleTermsModel} />
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme?.customization?.themeColor === "dark" ? theme?.darkMode.sectionBackground : theme.palette.background.default,
          transition: leftDrawerOpened
            ? theme.transitions.create("width")
            : "none",
        }}
      >
        <Toolbar style={{ padding: '12.5px' }}>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} leftDrawerOpened={leftDrawerOpened} />
        </Toolbar>
        <Box sx={{  }} className="mobileAccountDropDownView">
          <AccountDropDownSection />
        </Box>
      </AppBar>

      {/* drawer */}
      <Sidebar
        drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
      />

      {/* main content */}
      <Main
        theme={theme}
        open={leftDrawerOpened}
        sx={{ display: "flex", flexDirection: "column", marginRight: '0px' }}
        // style={{width:"100%", overflowX:"auto", width:"100%"}}
        className='mainPageContent'
      >
        {/* breadcrumb */}
        <Breadcrumbs
          card={false}
          separator={IconChevronRight}
          navigation={navigation}
          icon
          title
          rightAlign
        />
        <Outlet />
        <Box
          sx={{
            py: 2,
            px: 2,
            mt: "auto",
            pt: 4
          }}
          component="footer"
        >
          <Container maxWidth="sm">
            <Typography variant="body1" textAlign={"center"} sx={{...theme.typography.copyrightText}}>
              {Constants.appName} © {moment().format('YYYY')}. {Constants.footerText.rights} | Version {packageJson.version} | <a style={{ cursor: "pointer" }} href={CommonConstants.privacy_policy_link} target="_blank" >{Constants.footerText.privacy}</a> | <a href={CommonConstants.terms_link} target="_blank" style={{ cursor: "pointer" }} >{Constants.footerText.terms}</a> 
            </Typography>
          </Container>
        </Box>
      </Main>
      <ChatBot />
      <Customization />
    </Box>
  );
};

export default MainLayout;
