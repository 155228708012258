//import PropTypes from 'prop-types';
import { forwardRef } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Unstable_Grid2 as Grid } from "@mui/material";

// ==============================|| CUSTOM Table Action Section ||============================== //

const TableActionSection = forwardRef(({ actionItems }, ref) => {
  const theme = useTheme();

  return (
    <Box ref={ref} sx={{ mb: 2 }}>
      <Grid container spacing={1} sx={{placeItems: 'center'}}>
        {actionItems.map((e) => {
          return (
            <Grid xs={12} {...e.sx}>
              {e.action}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
});

TableActionSection.propTypes = {};

export default TableActionSection;
