import React from "react";
import { createChatBotMessage, createCustomMessage } from "react-chatbot-kit";
import MarkdownParser from "../components/MarkdownParser";
import CustomMessage from "../components/CustomMessage";
import ChatbotIcon from "../components/ChatbotIcon";
import UserIcon from "../components/UserIcon";

const BotName = "Geek";

const config = {
  // Defines the chatbot name
  botName: BotName,

  // Defines an array of initial messages that will be displayed on first render
  initialMessages: [
    createChatBotMessage(`Hi, my name is ${BotName} !`, {loading: true}),
    createCustomMessage("Test", "custom", {loading: true, delay: 500}),
  ],

  state: {
    gist: "",
    infoBox: "",
  },

  customComponents: {
    // Replaces the default header
    // header: () => <div style={{ backgroundColor: 'red', padding: "5px", borderRadius: "3px" }}>This is the header</div>,
    // Replaces the default bot avatar
    botAvatar: (props) => <ChatbotIcon {...props} />,
    // Replaces the default user icon
    // userAvatar: (props) => <UserIcon {...props} />,
    // Replaces the default bot chat message container
    botChatMessage: (props) =>  <MarkdownParser {...props} />,
  },

  customMessages: {
    custom: (props) => <CustomMessage {...props} />,
  },

  widgets: [],

  customStyles: {
    // Overrides the chatbot message styles
    botMessageBox: {
      backgroundColor: "#376B7E",
    },
    // Overrides the chat button styles
    chatButton: {
      backgroundColor: "#00BEFA",
    },
  },
};

export default config;
